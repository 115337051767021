import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalPdfViewer } from '../../../components/print-viewer-pdf';
import PrintPdfPoPembelianForm from '../../../views/pembelian/po-pembelian/PrintPdfPoPembelianForm';
import PrintPdfPoPenjualanForm from '../../../views/penjualan/po-penjualan/PrintPdfPoPenjualanForm';
import PrintPdfSuratJalanTransferStokForm from '../../../views/stock/transfer-stock/PrintPdfSuratJalanTransferStokForm';
import PrintPdfInvoicePenjualanBanForm from '../../../views/penjualan/sub-penjualan/PrintPdfInvoicePenjualanBanForm';
import PrintPdfInvoicePenjualanOliForm from '../../../views/penjualan/sub-penjualan/PrintPdfInvoicePenjualanOliForm';
import PrintPdfInvoicePenjualanOthersForm from '../../../views/penjualan/sub-penjualan/PrintPdfInvoicePenjualanOthersForm';
import PrintPdfNotaForm from '../../../views/penjualan/debet-kredit-note/PrintPdfNotaForm';

import {
  PO_PEMBELIAN,
  PO_PENJUALAN,
  SURAT_JALAN_TRANSFER_STOCK,
  INVOICE_PENJUALAN_BAN,
  INVOICE_PENJUALAN_OLI,
  INVOICE_PENJUALAN_OTHERS,
  NOTA_DEBET_KREDIT,
  TOASTER_SHOW,
} from 'redux/actions/actionTypes';

/*
  ``````````````````
  useModalPreviewPdf

  ``````````````````
*/
const useModalPreviewPdf = (data = null, typeTemplate = '', title = '', printMode = false) => {
  const dispatch = useDispatch();

  const [isModal, setModalOpen] = useState(false);

  useEffect(() => {
    if (isModal === true) {
      dispatch({ type: TOASTER_SHOW, message: 'PDF Viewer..', isInfo: true });

      if (typeTemplate === '') {
        dispatch({ type: TOASTER_SHOW, message: 'Whoops, Tipe template belum di tentukan', isError: true });
      }
    }
  }, [dispatch, isModal, typeTemplate]);

  const ModalComponent = ({ children }) => {
    if (data == null || data == undefined) {
      if (title === '' || title == null || title == undefined) {
        dispatch({ type: TOASTER_SHOW, message: 'Whoops, title belum di tentukan !', isError: true });
      }

      return <p>.</p>;
    } else {
      return (
        <>
          {isModal && (
            <ModalPdfViewer isShowModal={isModal} setShowModal={setModalOpen} title={title !== null ? title : ''}>
              {typeTemplate === PO_PEMBELIAN ? (
                <PrintPdfPoPembelianForm dataPrint={data} printMode={printMode} />
              ) : typeTemplate === PO_PENJUALAN ? (
                <PrintPdfPoPenjualanForm dataPrint={data} printMode={printMode} />
              ) : typeTemplate === SURAT_JALAN_TRANSFER_STOCK ? (
                <PrintPdfSuratJalanTransferStokForm dataPrint={data} printMode={printMode} />
              ) : typeTemplate === INVOICE_PENJUALAN_BAN ? (
                <PrintPdfInvoicePenjualanBanForm dataPrint={data} printMode={printMode} />
              ) : typeTemplate === INVOICE_PENJUALAN_OLI ? (
                <PrintPdfInvoicePenjualanOliForm dataPrint={data} printMode={printMode} />
              ) : typeTemplate === INVOICE_PENJUALAN_OTHERS ? (
                <PrintPdfInvoicePenjualanOthersForm dataPrint={data} printMode={printMode} />
              ) : typeTemplate === NOTA_DEBET_KREDIT ? (
                <PrintPdfNotaForm dataPrint={data} printMode={printMode} />
              ) : (
                <p style={{ color: 'red' }}>Whoops, tipe template belum di tentukan atau data input bermasalah.</p>
              )}
            </ModalPdfViewer>
          )}
        </>
      );
    }
  };

  return [setModalOpen, ModalComponent, isModal];
};

export default useModalPreviewPdf;
