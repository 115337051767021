import Axios from '../../utilities/axios-config';

const PREFIX = 'PENJUALAN/PELUNASAN_PIUTANG';
const SET_OPTIONS_CUSTOMER = `${PREFIX}/SET_OPTIONS_CUSTOMER`;

const { REACT_APP_CUSTOMER_OPTIONS } = process.env;

const initialState = {
  optionsCustomer: [{ value: undefined, label: 'Load data...' }],
};

const reminderJatuhTempoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTIONS_CUSTOMER:
      return {
        ...state,
        optionsCustomer: action.payload,
      };
    default:
      return state;
  }
};

export default reminderJatuhTempoReducer;
