import React, { useState, useEffect, useCallback } from 'react';
import { CFormGroup, CLabel, CSelect } from '@coreui/react';
import { InfoMandatory } from 'components/text-info';
import CIcon from '@coreui/icons-react';

const indexOf = require('lodash').indexOf;
const trim = require('lodash').trim;
/*
  ```````````
  useDropdown

  ```````````
*/
const useDropdown = (label, defaultState, data, isDisabled = false) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    setState(defaultState);
  }, [defaultState]);

  const DropdownComponent = ({ mandatory = false }) => {
    const handleUpdateValue = e => {
      const { value } = e.target;
      setState(value);
    };

    const handleClickReset = useCallback(
      e => {
        e.preventDefault();
        setState('');
      },
      [state, defaultState],
    );

    return (
      <CFormGroup>
        <CLabel htmlFor={label}>
          {indexOf(label, '*') > -1 ? (
            <>
              {trim(label, '*')} <InfoMandatory />
            </>
          ) : (
            `${label}`
          )}
        </CLabel>
        <CSelect
          id={label}
          name={label}
          value={state}
          onChange={handleUpdateValue}
          onBlur={handleUpdateValue}
          disabled={isDisabled}
          invalid={mandatory === true ? true : false}
        >
          <option disabled hidden>
            {`${defaultState === '' || state == '' ? `-- Pilih ${label} --` : defaultState}`}
          </option>

          {data?.map((item, index) => {
            if (typeof item === 'object')
              return (
                <option key={index} value={item?.value}>
                  {item?.label}
                </option>
              );
            else
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
          })}
        </CSelect>
        {/* {state !== '' ? (
          <span className="d-flex justify-content-end">
            <CIcon
              name="cil-x"
              size="sm"
              className="mr-3 cursor-pointer"
              onClick={handleClickReset}
              style={{ marginTop: -25 }}
            />
          </span>
        ) : null} */}
      </CFormGroup>
    );
  };

  return [state, DropdownComponent, setState];
};

export default useDropdown;
