import React, { useState, useEffect, useRef } from 'react';
import { CFormGroup, CLabel } from '@coreui/react';
import Select from 'react-select';
import { InfoMandatory } from '../../../components/text-info';
const _ = require('lodash');
/*
  ```````````````````````
  useDropdownAutoComplete

  ```````````````````````
*/
export const stylesMandatory = {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: 'red',
    };
  },
};

export const stylesDefault = {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: '#cccccc',
    };
  },
};

const useDropdownAutoComplete = (label, defaultState, data, isDisabled = false, clearable = true) => {
  const [state, setState] = useState();
  const [arrData, setArrData] = useState([]);

  const handleChange = data => {
    setState(data);
  };

  useEffect(() => {
    setArrData(data instanceof Array ? data : []);
    const defaultValue = data?.find(item => item?.value === defaultState);
    if (defaultValue !== undefined) setState(defaultValue);
  }, [data, defaultState]);

  const DropdownAutoCompleteComponent = ({ mandatory = false }) => {
    const defaultValue = arrData?.find(item => item?.value === defaultState);

    return (
      <CFormGroup>
        <CLabel htmlFor={label}>
          {_.indexOf(label, '*') > -1 ? (
            <>
              {_.trim(label, '*')} <InfoMandatory />
            </>
          ) : (
            `${label}`
          )}
        </CLabel>
        <Select
          id={label}
          options={arrData}
          placeholder={<span>-- Pilih {label} --</span>}
          onChange={handleChange}
          isDisabled={isDisabled}
          value={state}
          defaultValue={defaultValue}
          styles={mandatory ? stylesMandatory : stylesDefault}
          isClearable={clearable}
          // isLoading={isLoading}
        />
      </CFormGroup>
    );
  };

  return [state, DropdownAutoCompleteComponent, setState];
};

export default useDropdownAutoComplete;
