import React, { useState, useEffect, useRef } from 'react';
/*
  ````````
  useInput

  ````````
*/

/**
 * @param {string} initialValue initial value for input type text/number. Harus di isi (*Mandatory)
 * @param {number} limitCharacter Tentukan batas maksimal karakter huruf, tidak di isi juga gpp (*Optional).
 *
 * @returns {object} Returns fetch interface to be consumed by react-query library
 *
 * @example
 * import {useInput} from '@hooks'; //*Import dari folder hooks.
 *
 * const {value: valueKodeBarang, bind: handleKodeBarang, isMax: maxLengthKodeBarang } = useInput( '', 20);
 *
 */

const useInput = (initialValue, limitCharacter = 1000) => {
  const ref = useRef(null);

  const [value, setValue] = useState(initialValue);
  const [isMax, setMax] = useState(false);
  const [isMin, setMin] = useState(false);

  useEffect(() => {
    if (initialValue !== '') {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (value !== undefined) {
      if (value?.length > limitCharacter) {
        setMax(true);
      } else {
        setMax(false);
      }

      if (value?.length < limitCharacter) {
        setMin(true);
      } else {
        setMin(false);
      }
    }
  }, [limitCharacter, value]);

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: e => {
        setValue(e.target.value);
      },
    },
    isMin: isMin,
    isMax: isMax,
    isRef: ref,
  };
};

export default useInput;
