import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducer/index';

const store = createStore(rootReducer, applyMiddleware(thunk));
store.subscribe(() => {
  // console.log(store.getState());
});

export default store;
