import {
  listBarang,
  listGroupNeraca,
  listGroupLAndR,
  listKodeSupplier,
  listWarehouseLocation,
  listMerk,
  listPattern,
  listCAI,
  listUkuran,
  listRim,
  listFunction,
  listTipe,
  listBank,
  listSales,
  listKodeSales,
} from '../../utilities/dummy-data';

import {
  SEARCH_DATA_WITH_FILTER,
  SEARCH_DATA_WITH_FILTER_SUCCESS,
  SEARCH_DATA_BASED_ON_COLUMN,
  SEARCH_DATA_BASED_ON_COLUMN_SUCCESS,
  RESET_FILTER_DATA,
  RUN_EXPORT,
  RUN_EXPORT_FINISH,
  RUN_EXPORT_PDF,
  RUN_EXPORT_PDF_FINISH,
} from '../actions/actionTypes';

const _ = require('lodash');
const {
  REACT_APP_ATTR_BARANG_KELOMPOK_BARANG_OPTIONS,
  REACT_APP_ATTR_BARANG_TIPE_BARANG,
  REACT_APP_ATTR_BARANG_TIPE,
  REACT_APP_ATTR_BARANG_MERK_OPTIONS,
  REACT_APP_ATTR_BARANG_CAI_OPTIONS,
  REACT_APP_SUPPLIER_OPTIONS,
  REACT_APP_ATTR_BARANG_SEGMEN_BARANG,
  REACT_APP_WILAYAH,
  REACT_APP_KOTA_OPTIONS,
  REACT_APP_SALESMAN_OPTIONS,
  REACT_APP_ATTR_BARANG_PATTERN,
  REACT_APP_ATTR_BARANG_RIM,
  REACT_APP_ATTR_BARANG_FUNCTION,
  REACT_APP_ATTR_BARANG_UKURAN,
  REACT_APP_GROUP_CUSTOMER,
  REACT_APP_GROUP_CUSTOMER_OPTIONS,
  REACT_APP_AKUN_REK_GL_OPTIONS,

  REACT_APP_CUSTOMER_DETAIL,
  REACT_APP_CUSTOMER_OPTIONS,
  REACT_APP_BANK_OPTIONS,
  REACT_APP_DAFTAR_BARANG_DETAIL,
  REACT_APP_GENERATE_SALES_KODE,
  REACT_APP_GENERATE_SUPPLIER_KODE,
  REACT_APP_NERACA_GROUP_OPTIONS,
  REACT_APP_RETUR_PEMBELIAN_INVOICE,
} = process.env;

const initialState = {
  optionsStatus: ['Semua', 'Tidak Aktif', 'Aktif'],
  optionsStatusNew: [
    { value: 0, label: 'Tidak Aktif' },
    { value: 1, label: 'Aktif' },
    { value: 'all', label: 'Semua' },
  ],
  optionsMerk: listMerk,
  optionsKelompokBarang: listBarang,
  optionsPattern: listPattern,
  optionsTipeBarang: listBarang,
  optionsJenisBisnis: ['Semua', 'B2B', 'B2C', 'Other'],
  // optionsJenisUsaha: ['Fleet/User', 'PT', 'Toko', 'Bengkel'],
  optionsJenisUsaha: ['End User', 'Retailer', 'Rental', 'Reseller'],
  optionsCAI: listCAI,
  optionsKodeSupplier: listKodeSupplier,
  optionsSatuanBesar: ['PCS', 'Liter'],
  optionsSatuanKecil: ['PCS', 'Liter'],
  optionsUkuran: listUkuran,
  optionsRim: listRim,
  optionsFunction: listFunction,
  optionsTipe: listTipe,
  optionsSegmenBarang: listBarang,
  optionsWilayah: listWarehouseLocation,
  optionsTOP: ['TOP', 'Cash'],
  optionsKota: listWarehouseLocation,
  optionsBank: listBank,
  optionsSalesman: listSales,
  optionsKodeSales: listKodeSales,
  optionsDivisi: listMerk,
  optionsPosisiInAkunGl: ['Debet', 'Kredit'],
  optionsJenisInAkunGl: ['Neraca', 'Biaya', 'Laba/Rugi'],
  optionsGroupNeraca: listGroupNeraca,
  optionsGroupLAndR: listGroupLAndR,
  optionsGroupCustomer: [{ value: '-', label: 'Load data...' }],
  optionsAkunGL: [],
  optionsCustomer: [],
  optionsInvoiceReturPembelian: [],

  dateFrom: '',
  dateTo: '',

  dateIN: '',
  dateOUT: '',

  selectedStatus: '',
  selectedMerk: '',
  selectedKelompokBarang: '',
  selectedPattern: '',
  selectedTipeBarang: '',
  selectedTipe: '',
  selectedJenisBisnis: '',
  selectedJenisUsaha: '',

  selectedSatuanBesar: '',
  selectedSatuanKecil: '',
  selectedCAI: '',
  selectedKodeSupplier: '',
  selectedUkuran: '',
  selectedRim: '',
  selectedFunction: '',
  selectedSegmenBarang: '',
  selectedWilayah: '',
  selectedTOP: '',
  selectedKota: '',
  selectedBankSatu: '',
  selectedNamaBankRekeningSatu: '',
  selectedNamaBankRekeningDua: '',
  selectedKotaInFakturPajak: '',
  selectedSalesman: '',
  selectedKodeSales: '',
  selectedDivisi: '',
  selectedPosisiInAkunGl: '',
  selectedJenisInAkunGl: '',

  filteredWith: {
    page: 1,
    rows_limit: 10,
    filter_sort: 'desc',
    filter_sort_by: 'created_at',
  },
  isLoaderExportPdf: false,
  isLoaderExport: false,
  isLoaderFilter: false,
  isLoaderSearch: false,
  isLoaderInisiate: false,
  filteredData: null,
  detailMasterBarang: {},
  detailGenerateSalesCode: {},
  detailGenerateSupplierCode: {},
};

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RUN_EXPORT:
      return {
        ...state,
        isLoaderExport: true,
      };

    case RUN_EXPORT_FINISH:
      return {
        ...state,
        isLoaderExport: false,
      };

    case RUN_EXPORT_PDF:
      return {
        ...state,
        isLoaderExportPdf: true,
      };

    case RUN_EXPORT_PDF_FINISH:
      return {
        ...state,
        isLoaderExportPdf: false,
      };

    case SEARCH_DATA_WITH_FILTER:
      return {
        ...state,
        ...action,
      };
    case SEARCH_DATA_WITH_FILTER_SUCCESS:
      return {
        ...state,
        ...action,
      };

    case SEARCH_DATA_BASED_ON_COLUMN:
      return {
        ...state,
        isLoaderSearchMasterData: true,
      };

    case SEARCH_DATA_BASED_ON_COLUMN_SUCCESS:
      return {
        ...state,
        isLoaderSearchMasterData: false,
        filteredData: action.filteredData,
      };

    case 'DATA_CREATE_BARANG':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_CAI':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_MERK':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_KELOMPOK_BARANG':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_PATTERN':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_RIM':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_FUNCTION':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_TIPE':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_SEGMEN_BARANG':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_TIPE_BARANG':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BARANG_UKURAN':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_GROUP_CUSTOMER':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_CUSTOMER':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_LOKASI_GUDANG':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_WILAYAH':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_ATTRIBUTE_BANK':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_AKUN_GL':
      return {
        ...state,
        ...action,
      };

    case 'DETAIL_CAI':
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_TIPE_BARANG}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_KELOMPOK_BARANG_OPTIONS}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_ATTR_BARANG_TIPE}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_MERK_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_CAI_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_SUPPLIER_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_SEGMEN_BARANG}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_WILAYAH}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_KOTA_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_SALESMAN_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_PATTERN}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_RIM}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_FUNCTION}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_ATTR_BARANG_UKURAN}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-detail-${REACT_APP_CUSTOMER_DETAIL}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_CUSTOMER_OPTIONS}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_AKUN_REK_GL_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_BANK_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case RESET_FILTER_DATA:
      return {
        ...state,
        filteredData: null, //*will be deprecated
        isLoaderFilter: false, //*will be deprecated
        isLoaderSearchMasterData: false,
      };
    case `inisiate-${REACT_APP_DAFTAR_BARANG_DETAIL}`:
      return {
        ...state,
        ...action,
      };

    case `RUN_LOADER_INISIATE`:
      return {
        ...state,
        isLoaderInisiate: true,
      };

    case `RESET_LOADER_INISIATE`:
      return {
        ...state,
        isLoaderInisiate: false,
      };

    case `RESET_DATA_DETAIL`:
      return {
        ...state,
        detailMasterBarang: {},
      };

    case `inisiate-${REACT_APP_GENERATE_SALES_KODE}`:
      return {
        ...state,
        detailGenerateSalesCode: action.detailGenerateSalesCode,
      };

    case `inisiate-${REACT_APP_GENERATE_SUPPLIER_KODE}`:
      return {
        ...state,
        detailGenerateSupplierCode: action.detailGenerateSupplierCode,
      };

    case `inisiate-${REACT_APP_GROUP_CUSTOMER}`:
      return {
        ...state,
        optionsGroupCustomer: action?.optionsGroupCustomer,
      };

    case `inisiate-${REACT_APP_GROUP_CUSTOMER_OPTIONS}`:
      return {
        ...state,
        optionsGroupCustomer: action?.optionsGroupCustomer,
      };

    case `inisiate-${REACT_APP_NERACA_GROUP_OPTIONS}`:
      return {
        ...state,
        optionsGroupNeraca: action?.optionsGroupNeraca,
      };

    case `inisiate-${REACT_APP_RETUR_PEMBELIAN_INVOICE}`:
      return {
        ...state,
        optionsInvoiceReturPembelian: action?.optionsInvoiceReturPembelian,
      };

    default:
      return state;
  }
};

export default masterDataReducer;
