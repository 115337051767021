import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CButton, CTooltip } from '@coreui/react';
import CIcon from '@coreui/icons-react';

import { DATA_IMPORT, MODAL_SHOW_IMPORT, TOASTER_SHOW } from '../../../redux/actions/actionTypes';
/*
  ````````````
  useImportCsv

  ````````````
*/
const useImportCsv = (typeAction = '', endpoint = '', btnName = '') => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  let { dataImport, isModal } = ui;

  const Component = () => {
    const handleImport = e => {
      if (e.target.files !== undefined && e.target.files !== null) {
        if (e.target.files.length > 0) {
          let data = {
            valueFiles: e.target.files[0],
            valueNameFile: e.target.files[0].name,
            valueSizeByte: e.target.files[0].size,
            endpoint: endpoint,
          };

          console.log('Data : ', data);

          if (typeAction !== '' && typeAction === DATA_IMPORT) {
            dispatch({ type: MODAL_SHOW_IMPORT, dataImport: data });
          } else if (typeAction !== DATA_IMPORT && typeAction !== '') {
            dispatch({ type: TOASTER_SHOW, message: 'Type action yang kamu butukan : DATA_IMPORT 🍵' });
          } else {
            dispatch({
              type: TOASTER_SHOW,
              message: 'Whoops, sepertinya kamu lupa kasih type action di useImportCsv() 🍵',
            });
          }
        } else {
          dispatch({
            type: TOASTER_SHOW,
            message: 'Whoops, ada kesalahan teknis ketika import. Silahkan coba lagi !',
          });
        }
      }
    };

    return (
      <CTooltip content="Import CSV" placement="left">
        {/* <CButton
          name="csv"
          type="button"
          size="sm"
          color="info"
          className="mr-3"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <CIcon name="cil-cloud-upload" />
          <input
            type="file"
            id="csv"
            name="csv"
            style={{ opacity: 0, width: 7, cursor: 'pointer' }}
            accept=".csv,.xlsx"
            onChange={handleImport}
          />
          <label htmlFor="csv" style={{ cursor: 'pointer', fontSize: 14, padding: 2 }}>
            Import
          </label>
        </CButton> */}
        <CButton type="button">
          <div style={{ display: 'flex', textAlign: 'center' }}>
            <label className="custom-file-upload">
              <input type="file" accept=".csv,.xlsx" onChange={handleImport} />
              <CIcon name="cil-cloud-upload" /> {btnName ? btnName : 'Import'}
            </label>
          </div>
        </CButton>
      </CTooltip>
    );
  };

  return {
    value: dataImport,
    component: Component,
    isModal: isModal,
  };
};

export default useImportCsv;
