import React from 'react';
import { number, string, bool } from 'prop-types';
import './styles.scss';

const LoadingSkeleton = ({ count, width, height, className, circle }) => {
  return (
    <div className={className}>
      {Array.from({ length: count }, (value, index) => {
        return (
          <div
            key={index}
            className="animate"
            style={{
              width: width,
              height: height,
              borderRadius: circle ? '50%' : height,
            }}
          ></div>
        );
      })}
    </div>
  );
};
LoadingSkeleton.defaultProps = {
  circle: false,
  count: 1,
  width: '100%',
  height: '50px',
  className: '',
};
LoadingSkeleton.propTypes = {
  circle: bool,
  className: string,
  count: number,
  height: string,
  width: string,
};
export default LoadingSkeleton;
