import React, { useState, useEffect } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CButton,
  CRow,
  CCol,
  CProgress,
  CSpinner,
} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_CLOSE_IMPORT } from '../../redux/actions/actionTypes';
import Axios from '../../utilities/axios-config';
import { useMutateData, useInterval } from '../../utilities/hook';

const ModalImport = props => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);

  let { isModalImport, dataImport } = ui;
  let { valueNameFile, valueFiles, valueSizeByte, valueSizeKiloByte, endpoint } = dataImport;

  const [valueProgressDummy, setValueProgress] = useState(0);

  const handleYesImport = () => {
    let data = new FormData();
    data.append('import_file', valueFiles);

    mutateData(data);
  };

  //#
  const [mutateData, isLoading, isSuccess, isError, isIdle] = useMutateData(endpoint);

  useEffect(() => {
    if (isError) {
      dispatch({ type: MODAL_CLOSE_IMPORT });
      setValueProgress(0);
    }

    if (isSuccess) {
      dispatch({ type: MODAL_CLOSE_IMPORT });
      setValueProgress(0);
    }
  }, [dispatch, isError, isSuccess]);

  useInterval(
    () => {
      setValueProgress(valueProgressDummy + 1);
    },
    isLoading === true ? 500 : null,
  );

  //

  return (
    <CModal show={isModalImport} onClose={() => dispatch({ type: MODAL_CLOSE_IMPORT })} size="lg">
      <CModalHeader closeButton>
        <CModalTitle>Import CSV</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol md="12">
            <p>
              Lanjutkan import file{' '}
              <i>
                <u>{valueNameFile}</u>
              </i>{' '}
              ?
            </p>
          </CCol>
          <CCol md="12" className="text-center">
            {isLoading && (
              <CProgress animated striped showValue color="info" value={valueProgressDummy} className="mb-1 bg-white" />
            )}
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={isLoading ? true : false}
          color="primary"
          variant="ghost"
          onClick={() => dispatch({ type: MODAL_CLOSE_IMPORT })}
        >
          Batal
        </CButton>{' '}
        <CButton disabled={isLoading ? true : false} color="primary" onClick={handleYesImport}>
          {isLoading ? <CSpinner color="white" size="sm" /> : 'Import sekarang'}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ModalImport;
