import { TOASTER_SHOW, TOASTER_CLOSE, RESET_FILTER_QUERY_PARAMS } from '../redux/actions/actionTypes';
import { useDispatch } from 'react-redux';

export const showNotify = message => {
  return dispatch => {
    dispatch({ type: TOASTER_SHOW, message });
  };
};

export const closeNotify = () => {
  return dispatch => {
    dispatch({ type: TOASTER_CLOSE });
  };
};
