import React, { useCallback } from 'react';
import { string } from 'prop-types';

const ErrorView = ({ errorMessage }) => {
  const handleRetryClick = useCallback(() => {
    window.location.reload();
  }, []);

  const handleHomeClick = useCallback(() => {
    window.location = '/';
  }, []);

  return (
    <div className="error-wrapper">
      <h1>Oops.. Something went wrong.</h1>

      {errorMessage && <p>{errorMessage}</p>}

      <div className="button-error-wrapper">
        <button type="button" onClick={handleRetryClick}>
          Retry
        </button>
        <button type="button" onClick={handleHomeClick}>
          Go To Home
        </button>
      </div>
    </div>
  );
};

ErrorView.propTypes = {
  errorMessage: string,
};

ErrorView.defaultProps = {
  errorMessage: '',
};

export default ErrorView;
