import React, { useState, useRef, forwardRef } from 'react';
import { CFormGroup, CLabel } from '@coreui/react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

/*
  ``````````````````
  useDateRangePicker

  ``````````````````
*/

const CustomStartDateComponent = forwardRef(({ value, onClick, props }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      style={{
        backgroundColor: 'transparent',
        padding: '6px 4px 6px 4px',
        borderRadius: '5px 0px 0px 5px',
        borderRight: 'none',
        borderRightColor: 'transparent',
        borderWidth: '1px',
        color: 'grey',
      }}
      onClick={onClick}
    >
      {value} -
    </button>
  );
});

const CustomEndDateComponent = forwardRef(({ value, onClick }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      style={{
        backgroundColor: 'transparent',
        padding: '6px 4px 6px 4px',
        borderRadius: '0px 5px 5px 0px',
        borderLeft: 'none',
        borderLeftColor: 'transparent',
        borderWidth: '1px',
        color: 'grey',
      }}
      onClick={onClick}
    >
      {value}
    </button>
  );
});

const useDateRangePicker = (propStartDate, propEndDate) => {
  const refStartDate = useRef(null);
  const refEndDate = useRef(null);

  const [startDate, setStartDate] = useState(propStartDate || new Date(dayjs().startOf('month')));
  const [endDate, setEndDate] = useState(propEndDate || new Date(dayjs().endOf('month')));

  const Component = () => {
    return (
      <CFormGroup>
        <CLabel htmlFor="rentang-tanggal">Rentang Tanggal</CLabel>

        <br />
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<CustomStartDateComponent ref={refStartDate} />}
        />

        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<CustomEndDateComponent ref={refEndDate} />}
          popperPlacement="bottom-start"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: '8px, 8px',
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport',
            },
          }}
        />
      </CFormGroup>
    );
  };

  return [startDate, endDate, Component, setStartDate, setEndDate];
};

export default useDateRangePicker;
