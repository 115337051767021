// import {  SEARCH_PO_PEMBELIAN_WITH_FILTER } from '../actions/actionTypes'
import {
  listBarang,
  listNoFaktur,
  listCustomer,
  listSales,
  listWarehouseLocation,
  listDataPrintSuratJalanTransferStok,
  listRekGL,
} from '../../utilities/dummy-data';

import {
  RESET_STOCK_BARANG_IN_GUDANG,
  RUN_LOADER_STOCK_BARANG,
  STOP_LOADER_STOCK_BARANG,
  FORCE_UPDATE_OPTIONS_BARANG,
  FORCE_CETAK_PDF,
} from '../actions/actionTypes';

const {
  REACT_APP_LOCATION_GUDANG_OPTIONS,
  REACT_APP_STOCK_BARANG_IN_GUDANG,
  REACT_APP_AKUN_REK_GL_OPTIONS,
  REACT_APP_ATTR_BARANG_KELOMPOK_BARANG_OPTIONS,
  REACT_APP_DAFTAR_BARANG_PRODUCT_OPTIONS,
  REACT_APP_CHECK_STOCK,
} = process.env;

const initialState = {
  optionsLokasiAwal: listWarehouseLocation,
  optionsLokasiTujuan: listWarehouseLocation,
  optionsLocationGudang: listWarehouseLocation,
  optionsJenisLaporan: ['Laporan Persediaan', 'Laporan HPP', 'Mutasi Barang', 'Stok Opname'],
  optionsKelompokBarang: listBarang,
  optionsBarang: listBarang,
  optionsGL: listRekGL,
  optionsGLName: listRekGL,

  dateFrom: '',
  dateTo: '',
  dateTransfer: '',
  dateStockOpname: '',

  selectedLokasiAwal: '',
  selectedKelompokBarang: '',
  selectedBarang: '',
  selectedLokasiTujuan: '',
  selectedLocationGudang: '',
  selectedJenisLaporan: '',
  valueTotalStockBarang: 0,

  valueNoTransfer: '',
  valueNoPolisi: '',
  dataPrint: {
    headerTitle: 'SUMBER BAN',
    listData: listDataPrintSuratJalanTransferStok,
    tableHeader: ['NO.', 'Nama Barang', 'MERK', 'Ukuran', 'QTY', 'JENIS', 'KETERANGAN'],
    valueAlamat: 'JL. JENDRAL SUDIRMAN 22A PAHLAWAN KEMUNING PALEMBANG',
    valueNpwp: '02.992.527.8038-3838',
    valueNamaSurat: 'SURAT MUTASI BARANG',
    valueLokasiAwal: 'GUDANG KAMBOJA',

    valueTanggal: '06/06/2020',
    valueLokasiTujuan: 'GUDANG TANJUNG MAS',
    valueNomorFaktur: 'SJ.0066353',
    valueNoPlatPolisi: '-',
    valueDriveName: '-',
    valueTime: '15.15',

    valueJumlahQty: '29',
    valueSuratKeluarBy: 'YOSEPH',
    valueSuratKeluarCheckedBy: 'MERRY',
  },
  filteredWith: {
    page: 1,
    rows_limit: 20,
    filter_sort: 'desc',
    filter_sort_by: 'created_at',
  },
  loaderStockBarang: false,
  jumlahBarangBasedLokasi: 0,
};

const stokReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_TRANSFER_STOCK_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_STOCK_OPNAME_WITH_FILTER':
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_LOCATION_GUDANG_OPTIONS}-awal`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_LOCATION_GUDANG_OPTIONS}-tujuan`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_STOCK_BARANG_IN_GUDANG}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_CHECK_STOCK}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_AKUN_REK_GL_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_AKUN_REK_GL_OPTIONS}-name`:
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_ATTR_BARANG_KELOMPOK_BARANG_OPTIONS}`:
      return {
        ...state,
        ...action,
      };

    case RESET_STOCK_BARANG_IN_GUDANG: //*For a while, in hold to use this case !
      return {
        ...state,
        optionsBarang: [{ value: '...', label: '' }],
      };

    case `${FORCE_UPDATE_OPTIONS_BARANG}`:
      return {
        ...state,
        optionsBarang: action?.optionsBarang,
      };

    case `${RUN_LOADER_STOCK_BARANG}`:
      return {
        ...state,
        loaderStockBarang: true,
      };

    case `${STOP_LOADER_STOCK_BARANG}`:
      return {
        ...state,
        loaderStockBarang: false,
      };

    case FORCE_CETAK_PDF:
      return {
        ...state,
        dataPrint: action?.dataPrint,
      };

    case `inisiate-${REACT_APP_DAFTAR_BARANG_PRODUCT_OPTIONS}`:
      return {
        ...state,
        optionsBarang: action?.optionsBarang,
      };

    default:
      return state;
  }
};

export default stokReducer;
