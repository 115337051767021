import React, { useState, useReducer, useContext } from 'react';

const InputPembayaranContext = React.createContext();
const InputPembayaranDispatchContext = React.createContext();

//*CONSTANTA
const actionTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  SET_SHOW_TABLE: 'SET_SHOW_TABLE',
  SET_HIDE_TABLE: 'SET_HIDE_TABLE',
  RUN_LOADER: 'RUN_LOADER',
  SET_STATE_JUMLAH: 'SET_STATE_JUMLAH',
  SET_SELECTED_NO_INVOICE: 'SET_SELECTED_NO_INVOICE',
  SET_DATA_FOR_TIPE_PEMBAYARAN_TUNAI: 'SET_DATA_FOR_TIPE_PEMBAYARAN_TUNAI',
  SET_SELECTED_TIPE_PEMBAYARAN: 'SET_SELECTED_TIPE_PEMBAYARAN',
  SET_SELECTED_ROW_TABLE: 'SET_SELECTED_ROW_TABLE',
  SET_DATA_FOR_TIPE_PEMBAYARAN_CEK_GIRO: 'SET_DATA_FOR_TIPE_PEMBAYARAN_CEK_GIRO',
  SET_DATA_FOR_TIPE_PEMBAYARAN_PEMBEBANAN_MASALAH: 'SET_DATA_FOR_TIPE_PEMBAYARAN_PEMBEBANAN_MASALAH',
  OPEN_MODAL_DETAIL_PELUNASAN_HUTANG: 'OPEN_MODAL_DETAIL_PELUNASAN_HUTANG',
  CLOSE_MODAL_DETAIL_PELUNASAN_HUTANG: 'CLOSE_MODAL_DETAIL_PELUNASAN_HUTANG',
};

const {
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_MODAL,
  SET_SHOW_TABLE,
  SET_HIDE_TABLE,
  RUN_LOADER,
  SET_STATE_JUMLAH,
  SET_SELECTED_NO_INVOICE,
  SET_DATA_FOR_TIPE_PEMBAYARAN_TUNAI,
  SET_SELECTED_TIPE_PEMBAYARAN,
  SET_SELECTED_ROW_TABLE,
  SET_DATA_FOR_TIPE_PEMBAYARAN_CEK_GIRO,
  SET_DATA_FOR_TIPE_PEMBAYARAN_PEMBEBANAN_MASALAH,
  OPEN_MODAL_DETAIL_PELUNASAN_HUTANG,
  CLOSE_MODAL_DETAIL_PELUNASAN_HUTANG,
} = actionTypes;

//#
const initialState = {
  isShowModalDetailPelunasaanHutang: false,
  isShowModal: false,
  isShowTable: false,
  loading: false,
  jumlah: 0,
  jumlahSelisih: 0,
  dataTipeTunai: {
    gl_account: '',
  },
  dataTipeCekGiro: {
    no_cek_giro: '',
    // bank: ''
    gl_account: '',
    jatuh_tempo_cek_giro: '',
  },
  dataTipePembebananSelisih: {
    gl_account: '',
  },
  selectedNoInvoice: '',
  selectedValueTipePembayaran: '',
  selectedValueRowTable: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, isShowModal: true, jumlah: 0 };

    case CLOSE_MODAL:
      return { ...state, isShowModal: false };

    case TOGGLE_MODAL:
      return { ...state, isShowModal: !state?.isShowModal };

    case OPEN_MODAL_DETAIL_PELUNASAN_HUTANG:
      return { ...state, isShowModalDetailPelunasaanHutang: true };

    case CLOSE_MODAL_DETAIL_PELUNASAN_HUTANG:
      return { ...state, isShowModalDetailPelunasaanHutang: false };

    case SET_SHOW_TABLE:
      return {
        ...state,
        isShowTable: true,
      };

    case SET_HIDE_TABLE:
      return {
        ...state,
        isShowTable: false,
      };

    case RUN_LOADER:
      return {
        ...state,
        loading: action?.params,
      };

    case SET_STATE_JUMLAH:
      return {
        ...state,
        jumlah: action?.params,
      };

    case SET_SELECTED_NO_INVOICE:
      return {
        ...state,
        selectedNoInvoice: action?.params,
      };

    case SET_DATA_FOR_TIPE_PEMBAYARAN_TUNAI:
      return {
        ...state,
        dataTipeTunai: { gl_account: action?.params },
      };

    case SET_DATA_FOR_TIPE_PEMBAYARAN_CEK_GIRO:
      return {
        ...state,
        dataTipeCekGiro: action?.params,
      };

    case SET_DATA_FOR_TIPE_PEMBAYARAN_PEMBEBANAN_MASALAH:
      return {
        ...state,
        dataTipePembebananSelisih: action?.params,
      };

    case SET_SELECTED_TIPE_PEMBAYARAN:
      return {
        ...state,
        selectedValueTipePembayaran: action?.params,
      };

    case SET_SELECTED_ROW_TABLE:
      return {
        ...state,
        selectedValueRowTable: action?.params,
      };
  }
};

function InputPembayaranProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showModal = () => dispatch({ type: OPEN_MODAL });
  const closeModal = () => dispatch({ type: CLOSE_MODAL });
  const toggleModal = () => dispatch({ type: TOGGLE_MODAL });

  const showModalDetailPelunasanHutang = () => dispatch({ type: OPEN_MODAL_DETAIL_PELUNASAN_HUTANG });
  const closeModalDetailPelunasanHutang = () => dispatch({ type: CLOSE_MODAL_DETAIL_PELUNASAN_HUTANG });

  const setShowTable = () => dispatch({ type: SET_SHOW_TABLE });
  const setHideTable = () => dispatch({ type: SET_HIDE_TABLE });

  const runLoader = params => dispatch({ type: RUN_LOADER, params });

  const setJumlah = params => dispatch({ type: SET_STATE_JUMLAH, params });
  const setSelectedNoInvoice = params => dispatch({ type: SET_SELECTED_NO_INVOICE, params });
  const setDataTipeTunai = params => dispatch({ type: SET_DATA_FOR_TIPE_PEMBAYARAN_TUNAI, params });
  const setDataTipeCekGiro = params => dispatch({ type: SET_DATA_FOR_TIPE_PEMBAYARAN_CEK_GIRO, params });
  const setDataTipePembebananSelisih = params =>
    dispatch({ type: SET_DATA_FOR_TIPE_PEMBAYARAN_PEMBEBANAN_MASALAH, params });
  const setSelectedTipePembayaran = params => dispatch({ type: SET_SELECTED_TIPE_PEMBAYARAN, params });
  const setSelectedRowTable = params => dispatch({ type: SET_SELECTED_ROW_TABLE, params });

  let methods = {
    showModal,
    closeModal,
    toggleModal,
    setShowTable,
    setHideTable,
    runLoader,
    setJumlah,
    setSelectedNoInvoice,
    setDataTipeTunai,
    setDataTipeCekGiro,
    setDataTipePembebananSelisih,
    setSelectedTipePembayaran,
    showModalDetailPelunasanHutang,
    closeModalDetailPelunasanHutang,
    setSelectedRowTable,
  };

  return (
    <InputPembayaranContext.Provider value={state}>
      <InputPembayaranDispatchContext.Provider value={methods}>{children}</InputPembayaranDispatchContext.Provider>
    </InputPembayaranContext.Provider>
  );
}

const useInputPembayaranDispatch = () => {
  const context = useContext(InputPembayaranDispatchContext);

  if (context === undefined) {
    throw new Error('useInputPembayaranDispatch must be used within a InputPembayaranProvider');
  }
  return context;
};

const useInputPembayaran = () => {
  const context = useContext(InputPembayaranContext);

  if (context === undefined) {
    throw new Error('useInputPembayaran must be used within a InputPembayaranProvider');
  }

  return context;
};

export { InputPembayaranProvider, useInputPembayaran, useInputPembayaranDispatch };
