const initialState = {
  kode: '',
  companyConfig: {},
  documentConfigDetail: {},
};

const { REACT_APP_COMPANY_CONFIG_OPTIONS, REACT_APP_DOC_CONFIG_DETAIL } = process.env;
const konfigurasiSistemReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'EDITING_KONFIGURASI_DOKUMEN':
      return {
        ...state,
        ...action,
      };
    case `initiate-${REACT_APP_COMPANY_CONFIG_OPTIONS}`:
      return {
        ...state,
        ...action,
      };
    case `initiate-${REACT_APP_DOC_CONFIG_DETAIL}`:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default konfigurasiSistemReducer;
