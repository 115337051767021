import React, { useEffect } from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle, CButton, CSpinner } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutationDelete } from '../../utilities/hook';
import { MODAL_CLOSE_TRASH } from '../../redux/actions/actionTypes';

const _ = require('lodash');

const ModalTrash = props => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  let { dataDeleted, isModalTrash, endpoint, endpointRefetch } = ui;

  const runDelete = data => {
    console.log('Data deleted : ', data);

    let templateData = {
      _token: data?._token,
      method: data?.method == 'user-delete' ? 'user-delete' : 'delete',
      purchase_number: data?.purchase_number,
      nomor_po: data?.purchase_number,
      invoice_number: data?.invoice_number,
      no_penerimaan: data?.received_number,
    };

    const sanitizedParams = _.pickBy(templateData, value => value !== '' && value !== undefined);
    console.log('sanitizedParams : ', sanitizedParams);

    mutateDelete(sanitizedParams);

    // mutateDelete({
    //   _token: data?._token,
    //   method: 'delete',
    //   purchase_number: data?.purchase_number,
    //   nomor_po: data?.purchase_number,
    // });
  };

  const [mutateDelete, isLoad, isSuccess] = useMutationDelete(endpoint, endpointRefetch);

  useEffect(() => {
    if (isSuccess === true) {
      dispatch({ type: MODAL_CLOSE_TRASH });
    }
  }, [dispatch, isSuccess]);

  return (
    <CModal show={isModalTrash} onClose={() => dispatch({ type: MODAL_CLOSE_TRASH })} color="danger">
      <CModalHeader closeButton>
        <CModalTitle>
          <CIcon name="cil-trash" size="lg" />
          {/* CAI */}
        </CModalTitle>
      </CModalHeader>
      <CModalBody className="text-center">
        <h5 style={{ marginTop: 24, marginBottom: 24 }}>Apakah Anda yakin menghapus data ini ?</h5>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => dispatch({ type: MODAL_CLOSE_TRASH })}>
          Tidak
        </CButton>
        <CButton color="primary" onClick={() => runDelete(dataDeleted)} disabled={isLoad ? true : false}>
          {isLoad == true ? <CSpinner color="white" size="sm" /> : 'Ya'}
        </CButton>{' '}
      </CModalFooter>
    </CModal>
  );
};

export default ModalTrash;
