import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Axios from '../../axios-config';
import {
  MODAL_CLOSE_IMPORT,
  TOASTER_SHOW,
  TOASTER_CLOSE,
  LINEAR_PROGRESS_BAR_CLOSE,
  LINEAR_PROGRESS_BAR_SHOW,
} from '../../../redux/actions/actionTypes';
const _ = require('lodash');
/*
  `````````````
  useQueryDataDetail

  `````````````
*/

const handleQueryDetail = async (endpointName, paramsId) => {
  const { data } = await Axios.post(endpointName, paramsId);
  return data;
};

const useQueryDataDetail = (endpointName = '') => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { mutate: mutateData, isLoading, isSuccess, data, status, isError, isIdle } = useMutation(
    paramsId => handleQueryDetail(endpointName, paramsId),
    {
      onError: err => {
        dispatch({ type: LINEAR_PROGRESS_BAR_CLOSE });

        if (err.response !== null && err.response !== undefined) {
          if (err.response.data !== null && err.response.data !== undefined) {
            if (err.response.data.msg !== null && err.response.data.msg !== undefined) {
              dispatch({ type: TOASTER_SHOW, message: err.response.data && err.response.data.msg, isError: true });

              if (err.response.status === 401) {
                localStorage.removeItem('token');
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            }
          }
        } else {
          dispatch({ type: TOASTER_SHOW, message: 'Whoops, somethin went wrong !', isError: true });
        }
      },
    },
  );

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: LINEAR_PROGRESS_BAR_SHOW });
    }

    if (isSuccess === true) {
      dispatch({ type: LINEAR_PROGRESS_BAR_CLOSE });
    }
  }, [isSuccess, isLoading, dispatch]);

  return [mutateData, data, isLoading, isSuccess, isError, isIdle];
};

export default useQueryDataDetail;
