export const formatAsCurrency = number => {
  const belowZero = number < 0;
  const numberAbs = Math.abs(number);
  const [left, right] = numberAbs.toString().split('.');
  let numberWithThousandSeparator = left.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (right) numberWithThousandSeparator += `,${right}`;
  return `${belowZero ? '- ' : ''}Rp ${numberWithThousandSeparator}`;
};

export const clampPrecission = (number, precission = 5) => {
  return Math.round(number * 10 ** precission) / 10 ** precission;
};
