import { combineReducers } from 'redux';
import ui from './uiReducer';
import modulPembelian from './pembelianReducer';
import modulPenjualan from './penjualanReducer';
import modulStok from './stokReducer';
import modulKasBank from './kasBankReducer';
import modulAccounting from './accountingReducer';
import modulUserManagement from './userManagementReducer';
import modulKonfigurasiSistem from './konfigurasiSistemReducer';
import modulReportAll from './reportAllReducer';
import modulMasterData from './masterDataReducer';
import modulManageSubsidiary from './manageSubsidiaryReducer';
import modulPOPenjualan from './poPenjualanReducer';
import modulSubPenjualan from './subPenjualanReducer';
import modulReturPenjualan from './returPenjualanReducer';
import modulJurnalUmum from './jurnalUmumReducer';
import modulPelunasanPiutang from './pelunasanPiutangReducer';
import modulReminderJatuhTempo from './reminderJatuhTempoReducer';

export default combineReducers({
  ui,
  modulPembelian,
  modulPenjualan,
  modulStok,
  modulKasBank,
  modulAccounting,
  modulUserManagement,
  modulKonfigurasiSistem,
  modulReportAll,
  modulMasterData,
  modulManageSubsidiary,
  modulPOPenjualan,
  modulSubPenjualan,
  modulReturPenjualan,
  modulJurnalUmum,
  modulPelunasanPiutang,
  modulReminderJatuhTempo,
});
