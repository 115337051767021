import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import App from './App';
import { icons } from './assets/icons';
import store from './store';
import Toaster from './components/toaster/Toaster';
import ModalTrash from './components/ModalTrash';
import ModalImport from './components/ModalImport';

import ErrorBoundary from './components/ErrorBoundary';
import ErrorView from './components/ErrorView';

import * as serviceWorker from './serviceWorker';
import './polyfill';
import initLogger from './logger';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/id';

dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.locale('id');

initLogger();

React.icons = icons;

const queryClient = new QueryClient();

ReactDOM.render(
  <ErrorBoundary render={() => <ErrorView />}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
        <Toaster />
        <ModalTrash />
        <ModalImport />
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
