import React from 'react';
import PropTypes from 'prop-types';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { Row, Column, P, Divider, Header, Paper, BorderBox, Box } from 'components/print-viewer-pdf';
import { formatAsCurrency } from 'utilities/number';
const dayjs = require('dayjs');
const PrintPdfNotaForm = props => {
  let { dataPrint, printMode } = props;
  let {
    headerTitle = '',
    tableHeader = [],
    tableHeaderInvoice = [],

    customer_label = '',
    nomor_nota = '',
    rekening_gl_label = '',
    tanggal = '',
    details = [],
  } = dataPrint;

  return (
    <Paper size="A3" background="#E4E4E4" ruler={false} printMode={printMode}>
      <BorderBox>
        <Header align="center" bold>
          {headerTitle}
        </Header>

        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}>
            <P bold>Nomor Nota :</P>
            <P>{nomor_nota || '-'}</P>

            <Divider mt={8} mb={8} />

            <P bold>Tanggal :</P>
            <P>{tanggal}</P>

            <Divider mt={8} mb={8} />

            <P bold>Supplier/Customer</P>
            <P>{customer_label || '-'}</P>

            <Divider mt={8} mb={8} />

            {/* <P bold>Nama Rekening GL</P>
            <P>{rekening_gl_label || '-'}</P> */}
          </Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}></Column>
        </Row>

        {details.map(({ detail_invoice, detail_nota }) => (
          <>
            <Box mHorizontal={40} mVertical={4}>
              <Table data={[detail_invoice] || []}>
                <TableHeader textAlign="center">
                  {tableHeaderInvoice.length > 0
                    ? tableHeaderInvoice.map((item, i) => (
                        <TableCell key={i}>
                          <P bold>{item}</P>
                        </TableCell>
                      ))
                    : null}
                </TableHeader>
                <TableBody textAlign="center">
                  <DataTableCell
                    getContent={r => dayjs(r?.invoice_date, ['DD/MM/YYYY', 'YYYY-MM-DD']).format('DD/MM/YYYY') || '-'}
                  />
                  <DataTableCell getContent={r => r?.invoice_number || '-'} />
                  <DataTableCell getContent={r => r?.sisa?.string || '-'} />
                </TableBody>
              </Table>
            </Box>

            <Box mHorizontal={40} mVertical={4}>
              <Table data={detail_nota || []}>
                <TableHeader textAlign="center">
                  {tableHeader.length > 0
                    ? tableHeader.map((item, i) => (
                        <TableCell key={i}>
                          <P bold>{item}</P>
                        </TableCell>
                      ))
                    : null}
                </TableHeader>
                <TableBody textAlign="center">
                  <DataTableCell getContent={r => r?.note || '-'} />
                  <DataTableCell getContent={r => formatAsCurrency(r?.jumlah) || '-'} />
                </TableBody>
              </Table>
            </Box>
          </>
        ))}

        <Row justifyContent="space-between">
          <Column mHorizontal={48} mVertical={32}>
            <P>Mengetahui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Menyetujui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Menerima,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
        </Row>
      </BorderBox>
    </Paper>
  );
};
PrintPdfNotaForm.defaultProps = {
  printMode: false,
};
PrintPdfNotaForm.propTypes = {
  dataPrint: PropTypes.object.isRequired,
  printMode: PropTypes.bool,
};
export default PrintPdfNotaForm;
