import React, { useState, useEffect, useCallback, useRef, forwardRef } from 'react';

/*
  ````````
  useHover

  ````````
*/
function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        const handleMouseOver = () => {
          // console.log("mouseOver");
          setValue(true);
        };
        const handleMouseOut = () => {
          // console.log("mouseOut");
          setValue(false);
        };

        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [], // Recall only if ref changes
  );

  return [ref, value];
}

export default useHover;
