import React from 'react';
import PropTypes from 'prop-types';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { Row, Column, P, Divider, Header, Paper, BorderBox, Box, RowFixed300 } from 'components/print-viewer-pdf';

const PrintPdfPoPenjualanForm = props => {
  let { dataPrint, printMode } = props;
  let {
    headerTitle,
    listData,
    tableHeader,

    valueNoSo,
    valueTanggal,
    valueType,

    valueCustomer,
    valueAlamatCustomer,
    valueKota,
    valueNpwp,

    valueNamaPenjual,
    valueAlamatPenjual,
    valueNoHpPenjual,

    valueDikirimKe,
    valueKirimKeAlamat,
    valurPenerimaKirimanAtasNama,
    valueNomorTelponPenerimaKiriman,
    valueKeterangan,
    valueTotalAmount,
  } = dataPrint;

  return (
    <Paper size="A3" background="#E4E4E4" ruler={false} printMode={printMode}>
      <BorderBox>
        <Header align="center" bold>
          {headerTitle}
        </Header>

        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}>
            <P bold>Nomor PO :</P>
            <P>{valueNoSo}</P>

            <Divider mt={8} mb={8} />

            <P bold>Tanggal :</P>
            <P>{valueTanggal}</P>

            <Divider mt={8} mb={8} />

            <P bold></P>
            <P>{valueType}</P>

            <Divider mt={8} mb={8} />

            <Box width={300}>
              <P>{valueNamaPenjual} </P>
            </Box>

            <Box width={300}>
              <P>{valueAlamatPenjual}</P>
            </Box>
            <P>{valueNoHpPenjual}</P>
            <Divider mt={8} mb={8} />
          </Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}>
            <P bold>Nama Customer :</P>
            <RowFixed300>
              <P>{valueCustomer}</P>
            </RowFixed300>

            <Divider mt={8} mb={8} />

            <P bold>Alamat :</P>
            <Divider mt={1} mb={1} />
            <RowFixed300>
              <P>{valueAlamatCustomer}</P>
            </RowFixed300>

            <Divider mt={8} mb={8} />

            <P bold>Kota :</P>
            <P>{valueKota}</P>

            <Divider mt={8} mb={8} />

            <P bold>NPWP :</P>
            <P>{valueNpwp}</P>

            <Divider mt={8} mb={8} />

            <P bold>No. Telepon :</P>
            <P>{valueNomorTelponPenerimaKiriman}</P>
          </Column>
        </Row>

        <Box mHorizontal={40} mVertical={4}>
          <Table data={listData}>
            <TableHeader textAlign="center">
              {tableHeader.length > 0
                ? tableHeader.map((item, i) => (
                    <TableCell key={i}>
                      <P bold>{item}</P>
                    </TableCell>
                  ))
                : null}
            </TableHeader>
            <TableBody>
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.no} />
              <DataTableCell getContent={r => r.nama_barang} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.qty} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.harga_satuan} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.total} />
            </TableBody>
          </Table>
          <P>Total : {valueTotalAmount}</P>
        </Box>

        <Row>
          <Column mHorizontal={48} mVertical={32}>
            <P bold>Keterangan :</P>
            <BorderBox width={600} height={64} padding={0} margin={0}>
              <P>{valueKeterangan}</P>
            </BorderBox>
          </Column>
        </Row>

        <Row>
          <Column mHorizontal={48} mVertical={32}>
            <P>Hormat Kami,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Mengetahui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Mengetahui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Disetujui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
        </Row>
      </BorderBox>
    </Paper>
  );
};
PrintPdfPoPenjualanForm.defaultProps = {
  printMode: false,
};
PrintPdfPoPenjualanForm.propTypes = {
  dataPrint: PropTypes.object.isRequired,
  printMode: PropTypes.bool,
};
export default PrintPdfPoPenjualanForm;
