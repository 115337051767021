import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pdf, Page, Document, Text } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintPdfPoPembelianForm from '../../../views/pembelian/po-pembelian/PrintPdfPoPembelianForm';
import PrintPdfPoPenjualanForm from '../../../views/penjualan/po-penjualan/PrintPdfPoPenjualanForm';
import PrintPdfSuratJalanTransferStokForm from '../../../views/stock/transfer-stock/PrintPdfSuratJalanTransferStokForm';
import PrintPdfInvoicePenjualanBanForm from '../../../views/penjualan/sub-penjualan/PrintPdfInvoicePenjualanBanForm';
import PrintPdfInvoicePenjualanOliForm from '../../../views/penjualan/sub-penjualan/PrintPdfInvoicePenjualanOliForm';

import {
  PO_PEMBELIAN,
  PO_PENJUALAN,
  SURAT_JALAN_TRANSFER_STOCK,
  INVOICE_PENJUALAN_BAN,
  INVOICE_PENJUALAN_OLI,
  TOASTER_SHOW,
} from '../../../redux/actions/actionTypes';

/*
  `````````````
  useCetakPrint

  `````````````
*/
const WhoopsDocs = () => (
  <Document>
    <Page>
      <Text style={{ color: 'red' }}>Whoops, tipe template belum di tentukan atau data input bermasalah.</Text>
    </Page>
  </Document>
);
function useCetakPrint(data = null, typeTemplate = '', printMode = true) {
  const dispatch = useDispatch();

  const handleCetakPoPembelian = async () => {
    if (data === null || data === null) {
      dispatch({ type: TOASTER_SHOW, message: 'Whoops, data input bermasalah !', isError: true });
    } else {
      dispatch({ type: TOASTER_SHOW, message: 'Cetak print...' });

      const blob = await pdf(
        typeTemplate === PO_PEMBELIAN ? (
          <PrintPdfPoPembelianForm dataPrint={data} printMode={printMode} />
        ) : typeTemplate === PO_PENJUALAN ? (
          <PrintPdfPoPenjualanForm dataPrint={data} printMode={printMode} />
        ) : typeTemplate === SURAT_JALAN_TRANSFER_STOCK ? (
          <PrintPdfSuratJalanTransferStokForm dataPrint={data} printMode={printMode} />
        ) : typeTemplate === INVOICE_PENJUALAN_BAN ? (
          <PrintPdfInvoicePenjualanBanForm dataPrint={data} printMode={printMode} />
        ) : typeTemplate === INVOICE_PENJUALAN_OLI ? (
          <PrintPdfInvoicePenjualanOliForm dataPrint={data} printMode={printMode} />
        ) : (
          <WhoopsDocs />
        ),
      ).toBlob();

      const blobURL = await URL.createObjectURL(blob);

      await printJS(blobURL);
    }
  };

  return {
    bind: {
      onClick: handleCetakPoPembelian,
    },
  };
}

export default useCetakPrint;
