// import {  SEARCH_PO_PEMBELIAN_WITH_FILTER } from '../actions/actionTypes'

import {
  listBarang,
  listBank,
  listCustomer,
  listSales,
  listWarehouseLocation,
  listKodeKas,
  listKodeGL,
  listRekGL,
  listDataPrintPoPembelian,
  listDataPrintSuratJalan,
  listDataInvoicePenjualanBan,
  listPoPembelian,
  listMerk,
} from 'utilities/dummy-data';

import { FORCE_CETAK_INVOICE_PENJUALAN } from 'redux/actions/actionTypes';

const { REACT_APP_CUSTOMER_OPTIONS } = process.env;

const MUTASI_PIUTANG = 'Mutasi Piutang';
const LAPORAN_PIUTANG = 'Laporan Piutang';
const HISTORY_PELUNASAN = 'History Pelunasan';
const UMUR_PIUTANG = 'Umur Piutang';
const PIUTANG_JATUH_TEMPO = 'Piutang Jatuh Tempo';
const LAPORAN_SISA_PIUTANG = 'Laporan Sisa Piutang';
const ANALISA_PEMBAYARAN = 'Analisa Pembayaran';
const ANALISA_UMUR_PEMBAYARAN = 'Analisa Umur Pembayaran';

const initialState = {
  isDraft: true,

  optionsCustomer: listCustomer,
  optionsStatus: ['Semua', 'Diproses', 'Selesai'],
  optionsExport: ['PDF', 'CSV'],
  optionsSalesman: listSales,
  optionsJenisTransaksi: ['Penjualan', 'Retur', 'Rekap Quantity', 'Penjualan Oli', 'Laporan Khusus'],
  optionsJenisTransaksiInLaporanPiutang: [
    MUTASI_PIUTANG,
    LAPORAN_PIUTANG,
    HISTORY_PELUNASAN,
    UMUR_PIUTANG,
    PIUTANG_JATUH_TEMPO,
    LAPORAN_SISA_PIUTANG,
    ANALISA_PEMBAYARAN,
    ANALISA_UMUR_PEMBAYARAN,
  ],
  optionsTipeLaporan: ['Ringkas', 'Rinci'],
  optionsTipeLaporanInLaporanPiutang: ['Global', 'Rinci'],
  optionsFilterLaporan: ['Merk', 'Kelompok Barang', 'Pattern', 'Segmen', 'Function', 'Rim', 'Tipe Barang', 'Tipe'],
  optionsMerk: listMerk,
  optionsBarang: listBarang,
  optionsArea: listWarehouseLocation,
  optionsGudang: listWarehouseLocation,
  optionsTipePembayaran: ['Tunai', 'Cek/Giro', 'Pembebanan Selisih'],
  optionsKodeKas: listKodeKas,
  optionsKodeGL: listKodeGL,
  optionsBank: listBank,
  optionsGL: listRekGL,
  optionsPO: listPoPembelian,

  selectedExportTo: 'PDF',

  dataPrint: {
    headerTitle: 'Sales Order',
    listData: listDataPrintPoPembelian,

    tableHeader: ['No', 'Nama Barang', 'QTY', 'HARGA SATUAN', 'TOTAL HARGA'],

    listDataSuratJalan: listDataPrintSuratJalan,
    tableHeaderSuratJalan: ['NO', 'NAMA BARANG', 'QUANTITY', 'KETERANGAN'],

    listDataInvoicePenjualanBan: listDataInvoicePenjualanBan,
    tableHeaderInvoicePenjualanBan: ['NO', 'NAMA BARANG', 'QTY', '@HRG SAT', '@DISC', '@NETTO', 'JUMLAH'],

    listDataInvoicePenjualanOli: listDataInvoicePenjualanBan,
    tableHeaderInvoicePenjualanOli: ['NO', 'NAMA BARANG', 'QTY', 'HARGA', '@HRG SAT', '@DISC', '@NETTO', 'JUMLAH'],
    tableHeaderInvoicePenjualanSelainOliAndBan: ['NO', 'NAMA BARANG', 'QTY', '@HRG SAT', '@DISC', '@NETTO', 'JUMLAH'],

    valueNoSo: '-',
    valueTanggal: '-',
    valueType: 'T.O.P',

    valueCustomer: '-',
    valueAlamatCustomer: '-',
    valueKota: '-',
    valueNpwpCusto: '-',

    valueNamaPenjual: '-',
    valueAlamatPenjual: '-',
    valueNoHpPenjual: '-',
    valueNpwp: '-',

    valueDikirimKe: '-',
    valueKirimKeAlamat: '-',
    valurPenerimaKirimanAtasNama: '-',
    valueNomorTelponPenerimaKiriman: '-',

    //*Surat Jalan belum di buat utk typeTemplate/ typeAction-nya
    valueNoInvoice: '',
    valueWaktuCetakPlatMobil: '',
    valueTanggalJamKirim: '',
    valueNoKontakPenerima: '',

    //*INVOICE_PENJUALAN_BAN;
    valueAreaJual: '-',
    valueSalesman: '-',
    valueTop: '-',
    valueKet: '-',
    valueNoFaktur: '-',
    valueTanggalFaktur: '',
    valueDiscountC: '-',
    valueDiscountP: '-',
    valueRekeningBank: '',
    valueKeteranganPenbayaran: 'PEMBAYARAN DENGAN CHEQUE/ BILYET GIRO DIANGGAP SAH JIKA SUDAH CAIR.',

    //*INVOICE_PENJUALAN_OLI

    valueNamaBank: '-',
    valueNoRekening: '-',
    valueTotalAll: '-',
  },

  dataPrintNotaDebetKredit: {
    headerTitle: 'NOTA',
    tableHeader: ['Keterangan', 'Jumlah'],
    customer_label: '-',
    nomor_nota: '-',
    input_jumlah: [],
    rekening_gl_label: 'Dana',
    tanggal: '-',
  },

  //*
  filteredWith: {
    page: 1,
    rows_limit: 20,
    filter_sort: 'desc',
    filter_sort_by: 'created_at',
  },
  MUTASI_PIUTANG,
  LAPORAN_PIUTANG,
  HISTORY_PELUNASAN,
  UMUR_PIUTANG,
  PIUTANG_JATUH_TEMPO,
  LAPORAN_SISA_PIUTANG,
  ANALISA_PEMBAYARAN,
  ANALISA_UMUR_PEMBAYARAN,
};

const penjualanReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBMIT_DRAFT':
      return {
        ...state,
        isDraft: false,
      };

    case 'CANCEL_DRAFT':
      return {
        ...state,
        isDraft: true,
      };

    case 'CREATE_SUBMIT_POST_PENJUALAN_DATA':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_PO_PENJUALAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_PENJUALAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_RETUR_PENJUALAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_PIUTANG_PENJUALAN_PELUNASAN_PIUTANG_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_DEBET_NOTE_PIUTANG_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_KREDIT_NOTE_PIUTANG_DATA':
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_CUSTOMER_OPTIONS}`:
      return {
        ...state,
        optionsCustomer: action?.optionsCustomer,
      };

    case `FORCE_CETAK_PDF_NOTA_DEBIT_KREDIT`:
      return {
        ...state,
        dataPrintNotaDebetKredit: action?.dataPrintNotaDebetKredit,
      };

    case FORCE_CETAK_INVOICE_PENJUALAN:
      return {
        ...state,
        dataPrint: action?.dataPrint,
      };

    default:
      return state;
  }
};

export default penjualanReducer;
