import React, { useState } from 'react';
import { CFormGroup, CLabel } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoMandatory } from '../../../components/text-info';
const _ = require('lodash');
/*
  ``````````````````````````
  useSelectStatusRadioButton

  ``````````````````````````
*/
const useSelectStatusRadioButton = (
  title = 'Status *',
  defaultValue = { value: 1, label: 'Aktif' },
  isDisabled = false,
) => {
  const ui = useSelector(state => state.ui);
  let { optionsStatusActivated } = ui;

  const [status, setStatus] = useState(defaultValue);

  const Component = () => {
    return (
      <>
        <CLabel>
          {_.indexOf(title, '*') > -1 ? (
            <>
              {_.trim(title, '*')} <InfoMandatory />
            </>
          ) : (
            `${title}`
          )}
        </CLabel>
        <CFormGroup>
          {optionsStatusActivated.map((item, i) => (
            <React.Fragment key={i}>
              <input
                type="radio"
                onChange={() => setStatus(item)}
                id={item.label}
                name={item.label}
                value={item.value}
                disabled={isDisabled}
                checked={item.value === status.value ? true : false}
                className="mr-2"
              />
              <label className="mr-3" htmlFor={item.label}>
                {item.label}
              </label>
            </React.Fragment>
          ))}
        </CFormGroup>
      </>
    );
  };

  return {
    value: status,
    setValue: setStatus,
    component: Component,
  };
};

export default useSelectStatusRadioButton;
