import React from 'react';
import './index.css';

export const InisiateLoader = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Banindo Logo...</div>
  </div>
);

export const LoaderPage = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

/*
  ``````````````````
  LOADER DOT IN TEXT

  ``````````````````
*/

export const Wait = ({ children }) => <p className="loading">{children}</p>;
