// import {  SEARCH_PO_PEMBELIAN_WITH_FILTER } from '../actions/actionTypes'

import {
  listKodeSupplier,
  listBarang,
  listNoFaktur,
  listCustomer,
  listSales,
  listWarehouseLocation,
  listRekGL,
} from '../../utilities/dummy-data';

const initialState = {
  optionsTipeLaporanPengeluaranBiaya: ['Global', 'Rinci'],
  optionsGL: listRekGL,
  optionsJenis: ['Semua', 'Neraca', 'Laba/Rugi', 'Biaya'],
  dateFrom: '',
  dateTo: '',

  selectedTipeLaporanPengeluaranBiaya: '',
  valueNomor: '',
  valueDeskripsi: '',
  filteredWith: {
    page: 1,
    rows_limit: 20,
    filter_sort: 'desc',
    filter_sort_by: 'created_at',
  },
};

const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_JOURNAL_UMUM_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_JOURNAL_UMUM_DATA':
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export default accountingReducer;
