import Axios from '../../utilities/axios-config';

const PREFIX = 'PENJUALAN/PO_PENJUALAN';
const SET_OPTIONS_CUSTOMER = `${PREFIX}/SET_OPTIONS_CUSTOMER`;
const SET_OPTIONS_BARANG = `${PREFIX}/SET_OPTIONS_BARANG`;
const SET_OPTIONS_SALESMAN = `${PREFIX}/SET_OPTIONS_SALESMAN`;
const SET_OPTIONS_KELOMPOK_BARANG = `${PREFIX}/SET_OPTIONS_KELOMPOK_BARANG`;

const {
  REACT_APP_CUSTOMER_OPTIONS,
  REACT_APP_DAFTAR_BARANG_PRODUCT_OPTIONS,
  REACT_APP_SALESMAN_OPTIONS,
  REACT_APP_ATTR_BARANG_KELOMPOK_BARANG_OPTIONS,
} = process.env;

const initialState = {
  optionsCustomer: [{ value: '...', label: 'Load data...' }],
  optionsSalesman: [
    {
      value: undefined,
      label: 'Load data...',
    },
  ],
  optionsKelompokBarang: [
    {
      value: undefined,
      label: 'Load data...',
    },
  ],
  optionsBarang: [
    {
      value: undefined,
      label: 'Kelompok barang perlu diisi',
    },
  ],
};

const poPenjualanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTIONS_CUSTOMER:
      return {
        ...state,
        optionsCustomer: action.payload,
      };
    case SET_OPTIONS_BARANG:
      return {
        ...state,
        optionsBarang: action.payload,
      };
    case SET_OPTIONS_SALESMAN:
      return {
        ...state,
        optionsSalesman: action.payload,
      };
    case SET_OPTIONS_KELOMPOK_BARANG:
      return {
        ...state,
        optionsKelompokBarang: action.payload,
      };
    default:
      return state;
  }
};

export const loadOptions = () => {
  return async dispatch => {
    try {
      const responses = await Promise.all([
        Axios.get(REACT_APP_CUSTOMER_OPTIONS),
        Axios.get(REACT_APP_SALESMAN_OPTIONS),
        Axios.get(REACT_APP_ATTR_BARANG_KELOMPOK_BARANG_OPTIONS),
      ]);

      const optionsCustomer =
        responses[0]?.data.data?.map(item => ({
          ...item,
          value: item.customer_code,
          label: `${item.customer_code} - ${item.company_name}`,
        })) ?? [];
      const optionsSalesman =
        responses[1]?.data.data?.map(item => ({
          ...item,
          value: item.salesman_code,
          label: `${item.salesman_code} - ${item.salesman_name}`,
        })) ?? [];
      const optionsKelompokBarang =
        responses[2]?.data.data?.map(item => ({
          ...item,
          value: item.group_code,
          label: item.group_name,
        })) ?? [];

      dispatch({ type: SET_OPTIONS_CUSTOMER, payload: optionsCustomer });
      dispatch({ type: SET_OPTIONS_SALESMAN, payload: optionsSalesman });
      dispatch({ type: SET_OPTIONS_KELOMPOK_BARANG, payload: optionsKelompokBarang });
    } catch (error) {
      console.log('poPenjualanReducer: ', error.stack);
    }
  };
};

export const setKelompokBarang = kelompokBarang => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_OPTIONS_BARANG,
        payload: [{ value: undefined, label: 'Load data...' }],
      });
      if (!kelompokBarang?.group_code) {
        dispatch({
          type: SET_OPTIONS_BARANG,
          payload: [{ value: undefined, label: 'Kelompok barang perlu diisi' }],
        });
        return;
      }
      const response = await Axios.get(
        REACT_APP_DAFTAR_BARANG_PRODUCT_OPTIONS,
        // {
        //   rows_limit: 1000,
        //   page: 1,
        //   filter_kelompok_barang: kelompokBarang.group_code,
        // }
      );
      const optionsBarang = response?.data.data?.map(item => ({
        ...item,
        value: item.product_code,
        label: `${item.product_code} - ${item.product_name}`,
      }));
      dispatch({ type: SET_OPTIONS_BARANG, payload: optionsBarang });
    } catch (error) {
      dispatch({
        type: SET_OPTIONS_BARANG,
        payload: [{ value: undefined, label: 'Whoops, something went wrong!' }],
      });
      console.log('Error : ', error?.response || 'Whoops, something went wrong !');
    }
  };
};

export default poPenjualanReducer;
