import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Axios from '../../axios-config';
import { TOASTER_SHOW, LINEAR_PROGRESS_BAR_SHOW, LINEAR_PROGRESS_BAR_CLOSE } from '../../../redux/actions/actionTypes';

/*
  `````````````````
  useMutationDelete

  `````````````````
*/
const handleMutateDelete = async (endpointName, params) => {
  const { data } = await Axios.post(endpointName, params);
  return data;
};

const useMutationDelete = (endpointName = '', endpointNameRefetch = '') => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: mutateData, isLoading, isSuccess, data, status } = useMutation(
    params => handleMutateDelete(endpointName, params),
    {
      onError: err => {
        console.log('err : ', err.response.data);
        dispatch({ type: LINEAR_PROGRESS_BAR_CLOSE });

        if (err.response !== null && err.response !== undefined) {
          if (err.response.data !== null && err.response.data !== undefined) {
            if (err.response.data.msg !== null && err.response.data.msg !== undefined) {
              dispatch({ type: TOASTER_SHOW, message: err.response.data && err.response.data.msg, isError: true });
            }
          }
        } else {
          dispatch({ type: TOASTER_SHOW, message: 'Whoops, somethin went wrong !', isError: true });
        }
      },
      onSuccess: (data, variables) => {
        if (endpointNameRefetch == '' || endpointNameRefetch == undefined) {
          dispatch({
            type: TOASTER_SHOW,
            message: 'Whoops, sepertinya kamu lupa dengan "endpointNameRefetch"',
            isInfo: true,
          });
        } else {
          queryClient.invalidateQueries(endpointNameRefetch);
        }
      },
    },
  );

  useEffect(() => {
    // console.log("Status : ", status)
    if (isLoading) {
      dispatch({ type: LINEAR_PROGRESS_BAR_SHOW });
    }

    if (isSuccess === true) {
      if (!data?.result) {
        dispatch({ type: TOASTER_SHOW, message: data && data.msg, isError: true });
      } else {
        dispatch({ type: TOASTER_SHOW, message: data && data.msg });
      }

      dispatch({ type: LINEAR_PROGRESS_BAR_CLOSE });
    }
  }, [isSuccess, data, status, dispatch, isLoading]);

  return [mutateData, isLoading, isSuccess];
};

export default useMutationDelete;
