import React, { useState } from 'react';
import { CFormGroup, CLabel, CSelect, CForm } from '@coreui/react';
/*
  ``````````````````````
  useFilterCountRowTable

  ``````````````````````
*/
const useFilterCountTableRow = () => {
  const [tableRows, setTableRows] = useState(10);
  const [tablePage, setTablePage] = useState(1);

  const Component = ({ onChange }) => {
    return (
      <CForm inline>
        <CFormGroup className="pr-1">
          <CLabel htmlFor="jumlah-data" className="pr-1">
            Tampilkan
          </CLabel>
          <CSelect custom name="" id="jumlah-data" value={tableRows} onChange={onChange}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </CSelect>
          <CLabel htmlFor="jumlah-data" className="pl-1">
            Baris
          </CLabel>
        </CFormGroup>
      </CForm>
    );
  };

  return {
    valueRows: tableRows,
    setValueRows: setTableRows,
    component: Component,
    valuePages: tablePage,
    setValuePages: setTablePage,
  };
};

export default useFilterCountTableRow;
