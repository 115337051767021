/*
    `````
    UTILS

    `````
*/

import dayjs from 'dayjs';
export const dateFormatterYYYY_MM_DD = date => dayjs(date).format(TEXT_FORMAT_YYYY_MM_DD);
export const dateFormatterDD_MM_YYYY = date => dayjs(date).format(TEXT_FORMAT_DD_MM_YYYY_UPPERCASE);

/*
    ``
    UI

    ``
*/

export const TOASTER_SHOW = 'TOASTER_SHOW';
export const TOASTER_CLOSE = 'TOASTER_CLOSE';

export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_CLOSE = 'MODAL_CLOSE';

export const MODAL_SHOW_TRASH = 'MODAL_SHOW_TRASH';
export const MODAL_CLOSE_TRASH = 'MODAL_CLOSE_TRASH';

export const MODAL_SHOW_IMPORT = 'MODAL_SHOW_IMPORT';
export const MODAL_CLOSE_IMPORT = 'MODAL_CLOSE_IMPORT';

export const LINEAR_PROGRESS_BAR_SHOW = 'LINEAR_PROGRESS_BAR_SHOW';
export const LINEAR_PROGRESS_BAR_CLOSE = 'LINEAR_PROGRESS_BAR_CLOSE';

export const SET = 'set';

export const DATA_IMPORT = 'DATA_IMPORT';

export const REQUEST_INISIATE_MASTER_DATA = 'REQUEST_INISIATE_MASTER_DATA';

export const SET_VALUE_SEARCH_DEBOUNCED = 'SET_VALUE_SEARCH_DEBOUNCED';

export const ENABLE_LOADER_OPTIONS_CUSTOMER = 'ENABLE_LOADER_OPTIONS_CUSTOMER';

export const ENABLE_LOADER_OPTIONS_BARANG = 'ENABLE_LOADER_OPTIONS_BARANG';

export const SET_VALUE_FILTER_QUERY_PARAMS = 'SET_VALUE_FILTER_QUERY_PARAMS';

export const RESET_FILTER_QUERY_PARAMS = 'RESET_FILTER_QUERY_PARAMS';

/*
    ````````````````
    INTERACTION DATA

    ````````````````
*/
export const SEARCH_DATA_WITH_FILTER = 'SEARCH_DATA_WITH_FILTER';
export const SEARCH_DATA_WITH_FILTER_SUCCESS = 'SEARCH_DATA_WITH_FILTER_SUCCESS';

export const SEARCH_DATA_BASED_ON_COLUMN = 'SEARCH_DATA_BASED_ON_COLUMN';
export const SEARCH_DATA_BASED_ON_COLUMN_SUCCESS = 'SEARCH_DATA_BASED_ON_COLUMN_SUCCESS';

export const RESET_FILTER_DATA = 'RESET_FILTER_DATA';

export const RUN_EXPORT = 'RUN_EXPORT';
export const RUN_EXPORT_FINISH = 'RUN_EXPORT_FINISH';
export const RUN_EXPORT_PDF = 'RUN_EXPORT_PDF';
export const RUN_EXPORT_PDF_FINISH = 'RUN_EXPORT_PDF_FINISH';

/*
    ```````````````
    MODUL PEMBELIAN 

    ```````````````
*/
export const SEARCH_PO_PEMBELIAN_WITH_FILTER = 'SEARCH_PO_PEMBELIAN_WITH_FILTER';

/*
    ```````````````
    MODUL PENJUALAN 

    ```````````````
*/

export const FORCE_CETAK_INVOICE_PENJUALAN = 'FORCE_CETAK_INVOICE_PENJUALAN';

/*
    ``````````````
    MODUL KAS/BANK

    ``````````````
*/
export const SEARCH_KAS_BANK_WITH_FILTER = 'SEARCH_KAS_BANK_WITH_FILTER';

/*
    ```````````
    MODUL STOCK

    ```````````
*/
export const RESET_STOCK_BARANG_IN_GUDANG = 'RESET_STOCK_BARANG_IN_GUDANG';
export const FORCE_UPDATE_OPTIONS_BARANG = 'FORCE_UPDATE_OPTIONS_BARANG';
export const RUN_LOADER_STOCK_BARANG = 'RUN_LOADER_STOCK_BARANG';
export const STOP_LOADER_STOCK_BARANG = 'STOP_LOADER_STOCK_BARANG';
export const FORCE_CETAK_PDF = 'FORCE_CETAK_PDF';

/*
    ````````````````````
    TEMPLATE_CETAK_PRINT

    ```````````````````
*/
// const TEXT_TEMPLATE_INVOICE_PENJUALAN_BAN="BAN";
// const TEXT_TEMPLATE_INVOICE_PENJUALAN_OLI="OLI"
// const TEXT_TEMPLATE_INVOICE_PENJUALAN_OTHERS="OTHERS"
export const PO_PEMBELIAN = 'PO_PEMBELIAN';
export const PO_PENJUALAN = 'PO_PENJUALAN';
export const SURAT_JALAN_TRANSFER_STOCK = 'SURAT_JALAN_TRANSFER_STOCK';
export const INVOICE_PENJUALAN_BAN = 'BAN';
export const INVOICE_PENJUALAN_OLI = 'OLI';
export const INVOICE_PENJUALAN_OTHERS = 'OTHERS';
export const NOTA_DEBET_KREDIT = 'NOTA_DEBET_KREDIT';

/*
    ``````````````
    TEXT CONSTANTA

    ``````````````
*/

export const ACTIVE = 'active';

export const NON_ACTIVE = 'non_active';

export const ALL = 'all';

export const TYPE_OF_PAYMENT = 'TOP';

export const TEXT_SORTING_DESC = 'desc';

export const TEXT_SORTING_ASC = 'asc';

export const TEXT_INVALID_DATE = 'Invalid Date';

export const TEXT_FORMAT_DD_MM_YYYY = 'dd/MM/yyyy';

export const TEXT_FORMAT_DD_MM_YYYY_UPPERCASE = 'DD/MM/YYYY';

export const TEXT_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';

export const TEXT_STRING_ZERO = '0'; //Tidak Aktif

export const TEXT_INTEGER_ONE = 1; // Aktif

export const TEXT_AKTIF = 'Aktif';

export const TEXT_TIDAK_AKTIF = 'Tidak Aktif';

export const TEXT_SEMUA = 'Semua';
