import React, { useEffect } from 'react';
import { CToastHeader, CToastBody, CToaster, CToast, CAlert } from '@coreui/react';
import { useSelector, useDispatch } from 'react-redux';
import { closeNotify } from 'utilities/ui-actions';

const Toaster = props => {
  const dispatch = useDispatch();
  const { message, isOpen, isError, isInfo } = useSelector(state => state.ui);

  useEffect(() => {
    if (isOpen === true) {
      setTimeout(() => {
        dispatch(closeNotify());
      }, 5000);
    }
  }, [dispatch, isOpen]);

  return (
    <CToaster position="bottom-left">
      <CToast
        show={isOpen}
        // autohide={3000}
        fade
        style={{ border: '0px solid transparent', backgroundColor: 'transparent', backdropFilter: 'none' }}
      >
        <CAlert color={isError === true ? 'danger' : isInfo === true ? 'info' : 'success'}>{message}</CAlert>
        {/* 
                <CToastHeader  style={{backgroundColor: '#9e9e9e'}} closeButton={true} onClick={() => dispatch(closeNotify())}>                
                </CToastHeader>
                <CToastBody style={{backgroundColor: '#e0e0e0'}}>
                    {message}                
                </CToastBody> 
            */}
      </CToast>
    </CToaster>
  );
};

export default Toaster;
