export const listCustomer = [
  { value: '1', label: 'Sinar Mandraguna Indonesia' },
  { value: '2', label: 'Sinar Sakti Indonesia' },
  { value: '3', label: 'Sinar Cahaya Indonesia' },
  { value: '4', label: 'Jaya Ban' },
  { value: '5', label: 'Jaya Sejati' },
  { value: '5', label: 'Polygon' },
];

export const listWarehouseLocation = [{ value: '', label: '' }];

export const listBarang = [{ value: '...', label: 'Load data...' }];

export const listSales = [{ value: '...', label: 'Load data...' }];

export const listKodeSales = [
  { value: 'A0001', label: 'A0001' },
  { value: 'A0002', label: 'A0002' },
  { value: 'A0003', label: 'A0003' },
  { value: 'A0004', label: 'A0004' },
  { value: 'A0005', label: 'A0005' },
  { value: 'A0006', label: 'A0006' },
  { value: 'A0007', label: 'A0007' },
  { value: 'A0008', label: 'A0008' },
  { value: 'A0009', label: 'A0009' },
  { value: 'A0011', label: 'A0011' },
  { value: 'A0012', label: 'A0012' },
  { value: 'A0013', label: 'A0013' },
  { value: 'A0014', label: 'A0014' },
  { value: 'A0015', label: 'A0015' },
  { value: 'A0016', label: 'A0016' },
  { value: 'A0017', label: 'A0017' },
  { value: 'A0018', label: 'A0018' },
  { value: '1111', label: '1111' },
  { value: '2222', label: '2222' },
  { value: '3333', label: '3333' },
  { value: '4444', label: '4444' },
  { value: '5555', label: '5555' },
  { value: '6666', label: '6666' },
  { value: '7777', label: '7777' },
  { value: '8888', label: '8888' },
  { value: '9999', label: '9999' },
  { value: '11111', label: '11111' },
  { value: '22222', label: '22222' },
];

export const listPoPembelian = [
  { value: '1', label: 'PO-22332' },
  { value: '2', label: 'PO-223332' },
  { value: '3', label: 'PO-224788' },
  { value: '4', label: 'PO-226782' },
  { value: '5', label: 'PO-228880' },
  { value: '5', label: 'PO-2444444' },
];

export const listDataPrintPoPembelian = [
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '.',
    total: '.',
  },
];

export const listDataPrintSuratJalanTransferStok = [
  {
    no: '.',
    merk: '.',
    ukuran: '.',
    qty: '.',
    jenis: '.',
    keterangan: '',
  },
];

export const listDataPrintSuratJalan = [
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    keterangan: '',
  },
];

export const listDataInvoicePenjualanBan = [
  {
    no: '.',
    nama_barang: '.',
    qty: '.',
    harga_satuan: '',
    keterangan: '',
    disc: '',
    netto: '',
    jumlah: '',
  },
];

export const listRekGL = [
  {
    value: '-',
    label: 'Load data...',
  },
];

export const listKodeGL = [
  { value: '1', label: 'KODE-GL-1' },
  { value: '2', label: 'KODE-GL-2' },
  { value: '3', label: 'KODE-GL-3' },
  { value: '4', label: 'KODE-GL-4' },
  { value: '5', label: 'KODE-GL-5' },
  { value: '5', label: 'KODE-GL-6' },
];

export const listBank = [{ value: '-', label: 'Load data...' }];

export const listRoleName = [
  { value: 'Super Admin', label: 'Super Admin' },
  { value: 'Admin Penjualan', label: 'Admin Penjualan' },
  { value: 'Admin Pembelian', label: 'Admin Pembelian' },
  { value: 'Admin Gudang', label: 'Admin Gudang' },
];

export const listJenisLaporan = [
  'Laporan GL',
  'Laporan Neraca',
  'Laporan Laba/Rugi',
  'Laporan Pengeluaran Biaya',
  'Laporan Audit Trail',
];
export const listJenis = ['Semua', 'Neraca', 'Laba/Rugi', 'Biaya'];
export const listJenisv2 = [
  { value: '1', label: 'Jenis-1' },
  { value: '2', label: 'Jenis-2' },
  { value: '3', label: 'Jenis-3' },
];

export const listNoFaktur = [
  { value: '1', label: 'NO-FAKTUR-79879898798797' },
  { value: '2', label: 'NO-FAKTUR-FFF79879898798797' },
  { value: '3', label: 'NO-FAKTUR-7987GGKJGHJGKGGG98797' },
  { value: '4', label: 'NO-FAKTUR-7987GGG9879LKJLJLKJKL7' },
  { value: '5', label: 'NO-FAKTUR-7987GGG98797LLLLLL' },
  { value: '5', label: 'NO-FAKTUR-7987GGG98797UUUUUU' },
];

export const listMerk = [{ value: '...', label: 'Load data...' }];

export const listPattern = [{ value: '...', label: 'Load data...' }];

export const listCAI = [{ value: '...', label: 'Load data...' }];

export const listKodeSupplier = [
  { value: '1', label: 'KODE-SUPPLIER - Federal' },
  { value: '2', label: 'KODE-SUPPLIER - Bridegestone' },
  { value: '3', label: 'KODE-SUPPLIER - FDR' },
  { value: '4', label: 'KODE-SUPPLIER - IRC' },
  { value: '5', label: 'KODE-SUPPLIER - GT' },
  { value: '5', label: 'KODE-SUPPLIER - Polygon' },
];

export const listUkuran = [{ value: '...', label: 'Load data...' }];

export const listRim = [{ value: '...', label: 'Load data...' }];

export const listFunction = [{ value: '...', label: 'Load data...' }];

export const listTipe = [{ value: '...', label: 'Load data...' }];

export const listCekGiro = [
  { value: '1', label: 'GIRO-79879898798797' },
  { value: '2', label: 'GIRO-FFF79879898798797' },
  { value: '3', label: 'GIRO-7987GGKJGHJGKGGG98797' },
  { value: '4', label: 'GIRO-7987GGG9879LKJLJLKJKL7' },
  { value: '5', label: 'GIRO-7987GGG98797LLLLLL' },
  { value: '5', label: 'GIRO-7987GGG98797UUUUUU' },
];

export const listKodeKas = [
  { value: '1', label: 'KODE-KAS-79879898798797' },
  { value: '2', label: 'KODE-KAS-FFF79879898798797' },
  { value: '3', label: 'KODE-KAS-7987GGKJGHJGKGGG98797' },
  { value: '4', label: 'KODE-KAS-7987GGG9879LKJLJLKJKL7' },
  { value: '5', label: 'KODE-KAS-7987GGG98797LLLLLL' },
  { value: '5', label: 'KODE-KAS-7987GGG98797UUUUUU' },
];

export const listSubsidiary = [
  { value: '1', label: 'Subsidiary-1' },
  { value: '2', label: 'Subsidiary-2' },
  { value: '3', label: 'Subsidiary-3' },
];

export const listGroupNeraca = [
  { value: 'Kas', label: 'Kas' },
  { value: 'PiutGiro', label: 'PiutGiro' },
  { value: 'Bank', label: 'Bank' },
  { value: 'PiutDagang', label: 'PiutDagang' },
  { value: 'PiutLain2', label: 'PiutLain2' },
  { value: 'UMPembelian', label: 'UMPembelian' },
  { value: 'UMLain2', label: 'UMLain2' },
  { value: 'PreAsuransi', label: 'PreAsuransi' },
  { value: 'PreTax', label: 'PreTax' },
  { value: 'PPHFinal', label: 'PPHFinal' },
  { value: 'PreCost', label: 'PreCost' },
  { value: 'Persediaan', label: 'Persediaan' },
  { value: 'Tanah', label: 'Tanah' },
  { value: 'Gedung', label: 'Gedung' },
  { value: 'ADGedung', label: 'ADGedung' },
  { value: 'BangDlmPeny', label: 'BangDlmPeny' },
  { value: 'Kendaraan', label: 'Kendaraan' },
  { value: 'ADKendaraan', label: 'ADKendaraan' },
  { value: 'Inventaris1', label: 'Inventaris1' },
  { value: 'ADInventaris1', label: 'ADInventaris1' },
  { value: 'Inventaris2', label: 'Inventaris2' },
  { value: 'ADInventaris2', label: 'ADInventaris2' },
  { value: 'IjinMesinAlat', label: 'IjinMesinAlat' },
  { value: 'HutBank', label: 'HutBank' },
  { value: 'HutDagang', label: 'HutDagang' },
  { value: 'HutLain2', label: 'HutLain2' },
  { value: 'Modal', label: 'Modal' },
  { value: 'LabaThnBerjalan', label: 'LabaThnBerjalan' },
];

export const listGroupLAndR = [
  { value: 'Pembelian', label: 'Pembelian' },
  { value: 'RetPembelian', label: 'RetPembelian' },
  { value: 'Penjualan', label: 'Penjualan' },
  { value: 'RetPenjualan', label: 'RetPenjualan' },
  { value: 'HPPJual', label: 'HPPJual' },
  { value: 'BiayaKasKecil', label: 'BiayaKasKecil' },
  { value: 'BiayaLain2', label: 'BiayaLain2' },
  { value: 'BiayaKasKecil', label: 'BiayaKasKecil' },
  { value: 'BiayaAdmBank', label: 'BiayaAdmBank' },
  { value: 'BiayaPendapatanLain2', label: 'BiayaPendapatanLain2' },
  { value: 'BiayaPendapatanLain2', label: 'BiayaPendapatanLain2' },
];
