import Axios from '../../utilities/axios-config';

const PREFIX = 'PENJUALAN/RETUR_PENJUALAN';
const SET_OPTIONS_GL = `${PREFIX}/SET_OPTIONS_GL`;

const { REACT_APP_AKUN_REK_GL_OPTIONS } = process.env;

const initialState = {
  optionsGL: [{ value: undefined, label: 'Load date...' }],
};

const jurnalUmumReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTIONS_GL:
      return {
        ...state,
        optionsGL: action.payload,
      };
    default:
      return state;
  }
};

export const loadOptions = () => {
  return async dispatch => {
    try {
      const response = await Axios.get(REACT_APP_AKUN_REK_GL_OPTIONS);

      const optionsGL = response?.data.data?.map(item => ({
        ...item,
        value: item.code,
        label: `${item?.name_code}`,
      }));

      dispatch({ type: SET_OPTIONS_GL, payload: optionsGL });
    } catch (error) {
      console.log('jurnalUmumReducer: ', error.stack);
    }
  };
};

export default jurnalUmumReducer;
