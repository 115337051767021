import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Axios from '../../axios-config';
import {
  MODAL_CLOSE_IMPORT,
  TOASTER_SHOW,
  TOASTER_CLOSE,
  LINEAR_PROGRESS_BAR_CLOSE,
} from '../../../redux/actions/actionTypes';
const _ = require('lodash');
/*
  `````````````
  useMutateData

  `````````````
*/

const handleMutate = async (endpointName, params) => {
  const { data } = await Axios.post(endpointName, params);
  return data;
};

const useMutateData = (endpointName = '', endpointNameRefetch = undefined) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: mutateData, isLoading, isSuccess, data, status, isError, isIdle } = useMutation(
    params => handleMutate(endpointName, params),
    {
      onError: err => {
        if (err.response !== null && err.response !== undefined) {
          if (err.response.data !== null && err.response.data !== undefined) {
            if (err.response.data.msg !== null && err.response.data.msg !== undefined) {
              dispatch({ type: TOASTER_SHOW, message: err.response.data && err.response.data.msg, isError: true });

              if (err.response.status === 401) {
                localStorage.removeItem('token');
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              }
            }
          }
        } else {
          dispatch({ type: TOASTER_SHOW, message: 'Whoops, somethin went wrong !', isError: true });
        }
      },
    },
  );

  useEffect(() => {
    if (isSuccess === true) {
      dispatch({ type: TOASTER_SHOW, message: data && data.msg });
      dispatch({ type: LINEAR_PROGRESS_BAR_CLOSE });

      if (endpointNameRefetch !== undefined && history !== undefined) {
        console.log('Force refetch...');
        history.goBack();
        queryClient.refetchQueries(endpointNameRefetch);
      } else if (history !== undefined) {
        history.goBack();
      } else if (history == undefined) {
        queryClient.refetchQueries(endpointNameRefetch);
        dispatch({ type: MODAL_CLOSE_IMPORT });
      }
    }
  }, [isSuccess, data, status, dispatch, endpointNameRefetch, history, queryClient]);

  return [mutateData, isLoading, isSuccess, isError, isIdle];
};

export default useMutateData;
