import React, { useState, useReducer, useContext } from 'react';

const InputBarangContext = React.createContext();
const InputBarangDispatchContext = React.createContext();

//*CONSTANTA
const actionTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  SET_STATE_JUMLAH_BARANG: 'SET_STATE_JUMLAH_BARANG',
  SET_SELECTED_DATA_BARANG: 'SET_SELECTED_DATA_BARANG',
  SET_SHOW_TABLE: 'SET_SHOW_TABLE',
  SET_HIDE_TABLE: 'SET_HIDE_TABLE',
  SET_INTERACTION_INPUT_BARANG: 'SET_INTERACTION_INPUT_BARANG',
  SET_DATA_TABLE_INPUT_BARANG: 'SET_DATA_TABLE_INPUT_BARANG',
  RUN_LOADER: 'RUN_LOADER',
  TOGGLE_WITH_PO: 'TOGGLE_WITH_PO',
};

const {
  OPEN_MODAL,
  CLOSE_MODAL,
  TOGGLE_MODAL,
  INCREMENT,
  DECREMENT,
  SET_STATE_JUMLAH_BARANG,
  SET_SELECTED_DATA_BARANG,
  SET_SHOW_TABLE,
  SET_HIDE_TABLE,
  SET_INTERACTION_INPUT_BARANG,
  SET_DATA_TABLE_INPUT_BARANG,
  RUN_LOADER,
  TOGGLE_WITH_PO,
} = actionTypes;

//#
const initialState = {
  isShowModal: false,
  jumlahBarang: 0,
  selectedDataBarang: null,
  isShowTable: false,
  isInteractionInputBarang: false,
  dataTableInputBarang: [],
  loading: false,
  isWithPo: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, isShowModal: true, isShowTable: true, jumlahBarang: 0 };

    case CLOSE_MODAL:
      return { ...state, isShowModal: false };

    case TOGGLE_MODAL:
      return { ...state, isShowModal: !state?.isShowModal };

    case INCREMENT:
      return {
        ...state,
        jumlahBarang: state.jumlahBarang + 1,
      };

    case DECREMENT:
      return {
        ...state,
        jumlahBarang: state.jumlahBarang - 1,
      };

    case SET_STATE_JUMLAH_BARANG:
      return {
        ...state,
        jumlahBarang: action?.jumlahBarang,
      };

    case SET_SELECTED_DATA_BARANG:
      return {
        ...state,
        selectedDataBarang: action?.selectedDataBarang,
      };

    case SET_SHOW_TABLE:
      return {
        ...state,
        isShowTable: true,
      };

    case SET_HIDE_TABLE:
      return {
        ...state,
        isShowTable: false,
      };

    case SET_INTERACTION_INPUT_BARANG:
      return {
        ...state,
        isInteractionInputBarang: true,
      };

    case SET_DATA_TABLE_INPUT_BARANG:
      return {
        ...state,
        dataTableInputBarang: action?.dataTableInputBarang,
      };

    case RUN_LOADER:
      return {
        ...state,
        loading: action?.params,
      };
    case TOGGLE_WITH_PO:
      return {
        ...state,
        isWithPo: action?.params,
      };
  }
};

function InputBarangProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const showModal = () => dispatch({ type: OPEN_MODAL });
  const closeModal = () => dispatch({ type: CLOSE_MODAL });
  const toggleModal = () => dispatch({ type: TOGGLE_MODAL });

  const setIncrement = () => dispatch({ type: INCREMENT });
  const setDecrement = () => dispatch({ type: DECREMENT });
  const setStateJumlahBarang = params => dispatch({ type: SET_STATE_JUMLAH_BARANG, ...params });
  const setSelectedDataBarang = params => dispatch({ type: SET_SELECTED_DATA_BARANG, ...params });

  const setShowTable = () => dispatch({ type: SET_SHOW_TABLE });
  const setHideTable = () => dispatch({ type: SET_HIDE_TABLE });

  const setInteractionInputBarang = () => dispatch({ type: SET_INTERACTION_INPUT_BARANG });

  const setDataTableInputBarang = params => dispatch({ type: SET_DATA_TABLE_INPUT_BARANG, ...params });
  const runLoader = params => dispatch({ type: RUN_LOADER, params });
  const setTogglePo = params => dispatch({ type: TOGGLE_WITH_PO, params });

  let methods = {
    showModal,
    closeModal,
    toggleModal,
    setIncrement,
    setDecrement,
    setStateJumlahBarang,
    setSelectedDataBarang,
    setShowTable,
    setInteractionInputBarang,
    setHideTable,
    setDataTableInputBarang,
    runLoader,
    setTogglePo,
  };

  return (
    <InputBarangContext.Provider value={state}>
      <InputBarangDispatchContext.Provider value={methods}>{children}</InputBarangDispatchContext.Provider>
    </InputBarangContext.Provider>
  );
}

const useInputBarangDispatch = () => {
  const context = useContext(InputBarangDispatchContext);

  if (context === undefined) {
    throw new Error('useInputBarangDispatch must be used within a InputBarangProvider');
  }
  return context;
};

const useInputBarang = () => {
  const context = useContext(InputBarangContext);

  if (context === undefined) {
    throw new Error('useInputBarang must be used within a InputBarangProvider');
  }

  return context;
};

export { InputBarangProvider, useInputBarang, useInputBarangDispatch };
