import React, { useState, useRef, forwardRef } from 'react';
import { CFormGroup, CLabel } from '@coreui/react';
import DatePicker from 'react-datepicker';

const dayjs = require('dayjs');
/*
  ``````````````````
  useDatePicker

  ``````````````````
*/

const CustomDateComponent = forwardRef(({ value, onClick }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      style={{
        backgroundColor: 'transparent',
        padding: '6px 4px 6px 4px',
        borderRadius: '0px 5px 5px 0px',
        borderLeft: 'none',
        borderLeftColor: 'transparent',
        borderWidth: '1px',
        color: 'grey',
      }}
      onClick={onClick}
    >
      {value}
    </button>
  );
});

const useDatePicker = (defaultValue = '', label) => {
  const refDate = useRef(null);

  const [date, setDate] = useState(defaultValue ? new Date(defaultValue) : new Date());

  const Component = () => {
    return (
      <CFormGroup>
        <CLabel htmlFor="date">{label}</CLabel>

        <br />
        <DatePicker
          dateFormat="yyyy-MM-dd"
          // dateFormat="dd/MM/yyyy"
          className="form-control"
          wrapperClassName="w-100"
          id="date"
          selected={date}
          onChange={date => setDate(date)}
          startDate={date}
        />
      </CFormGroup>
    );
  };

  return [date, Component, setDate];
};

export default useDatePicker;
