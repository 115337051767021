import React from 'react';
import { CTooltip } from '@coreui/react';
const _ = require('lodash');

export const InfoCharacterLetterMoreThanLimit = () => (
  <span style={{ color: 'red' }}> ⛔ Jumlah karakter melebihi batas maksimal ! </span>
);

export const InfoMandatory = () => (
  <CTooltip
    content={
      <span style={{ color: 'yellow' }}>
        <b>Wajib di isi !</b>
      </span>
    }
    placement="right"
  >
    <span style={{ color: 'red' }}> *</span>
  </CTooltip>
);

export const InfoTextMandatory = () => <span style={{ color: 'red' }}> ⛔ Wajib di isi ! </span>;

export const InfoTextMandatoryWithTooltip = ({ message = 'Wajib di isi !' }) => (
  <CTooltip
    content={
      <span style={{ color: 'yellow' }}>
        <b>{message}</b>
      </span>
    }
    placement="right"
  >
    <span style={{ color: 'red' }}> ⛔ </span>
  </CTooltip>
);

export const stylesMandatory = {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: 'red',
    };
  },
};

export const stylesDefault = {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: '#cccccc',
    };
  },
};

export const isValidateObject = (errors, paramKey) =>
  typeof errors === 'object' && !errors !== undefined && _.has(errors, paramKey) ? true : null;

export const TextInfoEmptyDataTable = () => (
  <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: 24 }}>
    <h5 style={{ opacity: 0.5 }}>No Items</h5>
  </div>
);

export const INFO_REMIND_ISI_LOKASI_GUDANG = 'Isi data Lokasi Gudang terlebih dahulu !';
export const INFO_REMIND_ISI_LOKASI_AWAL = 'Isi data Lokasi awal barang terlebih dahulu !'; //*Sepertinya Anda lupa belum isi data lokasi awal barang dari mana.
export const INFO_WAJIB_ISI_LOKASI_GUDANG = 'Anda harus pilih Lokasi Gudang terlebih dahulu !';
export const INFO_REMIND_JUMLAH_PELUNASAN_HUTANG_MELEBIHI_DPP_PPN = 'Nominal jumlah sudah melebihi DPP + PPN !';

export const TUNAI = 'Tunai';
export const CEK_GIRO = 'Cek/Giro';
export const PEMBEBANAN_MASALAH = 'Pembebanan Selisih';
