import React, { useState, useMemo, useCallback, crea } from 'react';
import { CModal, CModalBody, CModalHeader, CModalFooter, CModalTitle, CButton, CSpinner } from '@coreui/react';
import CIcon from '@coreui/icons-react';

/*
  ``````````````
  useModalDelete

  ``````````````
*/

const useModalDelete = (handleDelete, title = '', isLoading = false) => {
  const [isModal, setModalOpen] = useState(false);
  const [dataDelete, setDataDelete] = useState('');
  // const showModal = data => {
  //   console.log('Data delete : ', data);
  //   setDataDelete(data);
  //   setModalOpen(!isModal);
  // };
  const showModal = useCallback(
    data => {
      console.log('Data delete : ', data);
      setDataDelete(data);
      setModalOpen(!isModal);
    },
    [isModal],
  );

  const ModalComponent = () => (
    <CModal show={isModal} onClose={setModalOpen} color="danger">
      <CModalHeader closeButton>
        <CModalTitle>
          <CIcon name="cil-trash" size="lg" /> {title}
        </CModalTitle>
      </CModalHeader>
      <CModalBody className="text-center">
        <h5 style={{ marginTop: 24, marginBottom: 24 }}>Apakah Anda yakin menghapus data {title} ini ?</h5>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => setModalOpen(false)}>
          Tidak
        </CButton>
        <CButton color="primary" onClick={handleDelete} disabled={isLoading ? true : false}>
          {isLoading == true ? <CSpinner color="white" size="sm" /> : 'Ya'}
        </CButton>{' '}
      </CModalFooter>
    </CModal>
  );

  return [showModal, ModalComponent, dataDelete, isModal];
};

export default useModalDelete;
