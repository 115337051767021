// import {  SEARCH_PO_PEMBELIAN_WITH_FILTER } from '../actions/actionTypes'

import {
  listBank,
  listBarang,
  listJenisv2,
  listNoFaktur,
  listCustomer,
  listSales,
  listWarehouseLocation,
  listSubsidiary,
  listKodeSupplier,
  listMerk,
  listA,
} from '../../utilities/dummy-data';

const initialState = {
  optionsJenisTransaksiPembelian: [
    { label: 'Pembelian Rinci', value: 'rinci' },
    { label: 'Pembelian Ringkas', value: 'ringkas' },
    { label: 'Retur Pembelian', value: 'retur' },
  ],
  optionsJenis: listJenisv2,
  optionsSubsidiary: listSubsidiary,
  optionsKasBank: listBank,
  optionsSupplier: listKodeSupplier,
  optionsNoFaktur: listNoFaktur,
  optionsKelompokBarang: listBarang,

  optionsJenisTransaksiPenjualan: ['Penjualan', 'Retur', 'Rekap Quantity', 'Penjualan Oli', 'Laporan Khusus'],
  optionsTipeLaporan: ['Ringkas', 'Rinci'],
  optionsTipeLaporanPenjualan: [
    { label: 'Rinci', value: 'rinci' },
    { label: 'Ringkas', value: 'ringkas' },
  ],
  optionsFilterLaporan: ['Merk', 'Kelompok Barang', 'Pattern', 'Segmen', 'Function', 'Rim', 'Tipe Barang', 'Tipe'],
  optionsSalesman: listSales,
  optionsCustomer: listCustomer,
  optionsMerk: listMerk,
  optionsArea: listWarehouseLocation,

  dateFrom: '',
  dateTo: '',

  selectedJenisTransaksiPembelian: '',
  selectedSubsidiary: '',
  selectedSupplier: '',
  selectedNoFaktur: '',
  selectedKelompokBarang: '',

  selectedJenisTransaksiPenjualan: '',
  selectedTipeLaporan: '',
  selectedFilterLaporan: '',
  selectedSalesman: '',
  selectedCustomer: '',
  selectedMerk: '',
  selectedArea: '',
};

const reportAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CETAK_PDF':
      return {
        ...state,
        ...action,
      };

    case 'CETAK_CSV':
      return {
        ...state,
        ...action,
      };

    default:
      return state;
  }
};

export default reportAllReducer;
