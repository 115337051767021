/* eslint-disable no-template-curly-in-string */
import React, { Suspense } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider, useAuthentication } from './context/authentication';
import { LoaderPage } from './components/loader';
import * as Yup from 'yup';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './scss/style.scss';

Yup.setLocale({
  mixed: {
    required: 'Wajib diisi!',
  },
  string: {
    email: 'Alamat email terlihat belum valid.',
    min: 'Jumlah karakter kurang!',
    max: 'Jumlah karakter melebihi batas maksimal!',
  },
  number: {
    min: 'Minimal ${min}',
    max: 'Maksimal ${min}',
  },
});

//#
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ResetPassword = React.lazy(() => import('./views/pages/reset-password'));
const VerifyOtp = React.lazy(() => import('./views/pages/login/VerifyOtp'));
const ForgetPasswordPage = React.lazy(() => import('./views/pages/forget-password'));
const ForgetPasswordConfigPage = React.lazy(() => import('./views/pages/forget-password-config'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const DocsCustomHook = React.lazy(() => import('./views/pages/docs/docs-custom-hook'));

//#
const AuthenticatedPages = React.lazy(() => import('./containers/TheLayout'));

//#
const UnAuthenticatedPages = () => {
  return (
    <Switch>
      <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
      <Route
        exact
        path="/forget-password-config"
        name="Atur ulang password"
        render={props => <ForgetPasswordConfigPage {...props} />}
      />
      <Route
        exact
        path="/forget-password"
        name="Forget Password Page"
        render={props => <ForgetPasswordPage {...props} />}
      />
      <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
      <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
      <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
      <Route exact path="/docs" name="Custom Hook" render={props => <DocsCustomHook {...props} />} />
      <Route exact path="/verify-otp" name="Verify OTP" render={props => <VerifyOtp {...props} />} />

      <Route exact path="/reset-password/:id" name="Reset Password" render={props => <ResetPassword {...props} />} />
      <Redirect from="/" to="/login" />
    </Switch>
  );
};

const BanindoApp = () => {
  const { token, user } = useAuthentication();

  return token ? <AuthenticatedPages /> : <UnAuthenticatedPages />;
};

const App = () => {
  return (
    <HashRouter>
      <Suspense fallback={<LoaderPage />}>
        <AuthProvider>
          <BanindoApp />
        </AuthProvider>
      </Suspense>
    </HashRouter>
  );
};

export default App;
