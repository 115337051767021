import React, { useMemo } from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react';

export const ModalPdfViewer = props => {
  const { isShowModal, setShowModal, title, children } = props;

  return (
    <CModal
      show={isShowModal}
      onClose={() => setShowModal(!isShowModal)}
      size="xl"
      style={{ width: '100%', height: '100%', margin: 0, padding: 0 }}
    >
      <CModalHeader closeButton style={{ backgroundColor: 'black' }}>
        <CModalTitle>{title} (Preview)</CModalTitle>
      </CModalHeader>
      <CModalBody style={{ width: '100%', height: '100%', margin: 0, padding: 0 }}>{children}</CModalBody>
    </CModal>
  );
};

export const Viewer = ({ children, width = '100%', height = 500 }) => (
  <PDFViewer
    style={{
      width: width,
      height: height,
    }}
  >
    {children}
  </PDFViewer>
);

export const Paper = ({ children, size, background, ruler, printMode = false, orientation }) => {
  if (printMode === true) {
    return (
      <Document>
        <Page
          size={size}
          style={{
            backgroundColor: background,
          }}
          ruler={ruler == true ? true : false}
          orientation={orientation}
        >
          {children}
        </Page>
      </Document>
    );
  } else {
    return (
      <Viewer>
        <Document>
          <Page
            size={size}
            style={{
              backgroundColor: background,
            }}
            ruler={ruler == true ? true : false}
            orientation={orientation}
          >
            {children}
          </Page>
        </Document>
      </Viewer>
    );
  }
};

export const BorderBox = ({
  children,
  borderWidth = 2,
  borderStyle = 'solid',
  borderColor = 'black',
  padding = 24,
  margin = 24,
  width,
  height,
}) => (
  <View
    style={{
      borderWidth: borderWidth,
      borderStyle: borderStyle,
      borderColor: borderColor,
      padding: padding,
      margin: margin,
      width: width,
      height: height,
    }}
  >
    {children}
  </View>
);

export const Row = ({ children, mHorizontal, mVertical, justifyContent }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      marginHorizontal: mHorizontal,
      marginVertical: mVertical,
      justifyContent: justifyContent,
    }}
  >
    {children}
  </View>
);

export const RowFixed300 = ({ children, mHorizontal, mVertical, justifyContent }) => (
  <View
    style={{
      width: 300,
    }}
  >
    {children}
  </View>
);

export const Column = ({ children, mHorizontal, mVertical, maxWidth }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'column',
      marginHorizontal: mHorizontal,
      marginVertical: mVertical,
      maxWidth: maxWidth,
      // justifyContent: justifyContent
    }}
  >
    {children}
  </View>
);

export const Divider = ({ children, mt, mb, ml, mr }) => (
  <View
    style={{
      marginTop: mt,
      marginBottom: mb,
      marginLeft: ml,
      marginRight: mr,
    }}
  >
    {children}
  </View>
);

export const Box = ({ children, mt, mb, ml, mr, width, height, mHorizontal, mVertical }) => (
  <View
    style={{
      marginTop: mt,
      marginBottom: mb,
      marginLeft: ml,
      marginRight: mr,
      width: width,
      height: height,
      marginHorizontal: mHorizontal,
      marginVertical: mVertical,
    }}
  >
    {children}
  </View>
);

/*
    ``````````````
    FONT HELVETICA

    ``````````````
*/

export const H6 = ({ children, bold, courier = false }) => (
  <Text
    style={{
      fontSize: '16',
      fontFamily: bold === true ? `Helvetica-Bold` : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

export const H5 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '18',
      fontFamily: bold === true ? 'Helvetica-Bold' : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

export const H4 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '20',
      fontFamily: bold === true ? 'Helvetica-Bold' : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

export const H3 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '24',
      fontFamily: bold === true ? 'Helvetica-Bold' : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

export const H2 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '30',
      fontFamily: bold === true ? 'Helvetica-Bold' : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

export const H1 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '30',
      fontFamily: bold === true ? 'Helvetica-Bold' : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

export const P = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '12',
      fontFamily: bold === true ? 'Helvetica-Bold' : 'Helvetica',
    }}
  >
    {children}
  </Text>
);

/*
    ````````````````
    FONT COURIER-NEW

    ````````````````
*/

export const Heading6 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '16',
      fontFamily: bold === true ? `Courier-Bold` : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Heading5 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '18',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Heading4 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '20',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Heading3 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '24',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Heading2 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '30',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Heading1 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '30',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Subtitle1 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '12',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
    }}
  >
    {children}
  </Text>
);

export const Subtitle2 = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '12',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
      // marginHorizontal: 'auto'
    }}
  >
    {children}
  </Text>
);

export const Caption = ({ children, bold }) => (
  <Text
    style={{
      fontSize: '1vw',
      fontFamily: bold === true ? 'Courier-Bold' : 'Courier',
      // marginHorizontal: 'auto'
    }}
  >
    {children}
  </Text>
);

//#
export const Header = ({ children, align = 'center', mt, mb, ml, mr, bold }) => (
  <View
    style={{
      display: 'flex',
      textAlign: align,
      marginTop: mt,
      marginBottom: mb,
      marginLeft: ml,
      marginRight: mr,
    }}
  >
    <H5 bold={bold}>{children}</H5>
  </View>
);
