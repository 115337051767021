import Axios from '../../utilities/axios-config';

const PREFIX = 'PENJUALAN/PELUNASAN_PIUTANG';
const SET_OPTIONS_CUSTOMER = `${PREFIX}/SET_OPTIONS_CUSTOMER`;
const SET_OPTIONS_GROUP_CUSTOMER = `${PREFIX}/SET_OPTIONS_GROUP_CUSTOMER`;
const SET_OPTIONS_GL = `${PREFIX}/SET_OPTIONS_GL`;
const SET_OPTIONS_BANK = `${PREFIX}/SET_OPTIONS_BANK`;

const {
  REACT_APP_CUSTOMER_OPTIONS,
  REACT_APP_GROUP_CUSTOMER_OPTIONS,
  REACT_APP_INISIATE_LIST_KODE_KAS_BANK,
  REACT_APP_BANK_OPTIONS,
} = process.env;

const initialState = {
  // optionsCustomer: [{ value: undefined, label: 'Load data...' }],
  optionsGroupCustomer: [{ value: undefined, label: 'Load data...' }],
  optionsGL: [{ value: undefined, label: 'Load data...' }],
  optionsBank: [{ value: undefined, label: 'Load data...' }],
};

const pelunasanPiutangReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OPTIONS_CUSTOMER:
      return {
        ...state,
        optionsCustomer: action.payload,
      };
    case SET_OPTIONS_GROUP_CUSTOMER:
      return {
        ...state,
        optionsGroupCustomer: action.payload,
      };
    case SET_OPTIONS_GL:
      return {
        ...state,
        optionsGL: action.payload,
      };
    case SET_OPTIONS_BANK:
      return {
        ...state,
        optionsBank: action.payload,
      };
    default:
      return state;
  }
};

export const loadOptions = () => {
  return async dispatch => {
    try {
      const responses = await Promise.all([
        // Axios.get(REACT_APP_CUSTOMER_OPTIONS),
        Axios.get(REACT_APP_GROUP_CUSTOMER_OPTIONS),
        Axios.post(REACT_APP_INISIATE_LIST_KODE_KAS_BANK, { rows_limit: 10000, page: 1 }),
        Axios.get(REACT_APP_BANK_OPTIONS),
      ]);

      // const optionsCustomer =
      //   responses[0]?.data.data?.map(item => ({
      //     ...item,
      //     value: item.customer_code,
      //     label: `${item.customer_code} - ${item.company_name}`,
      //   })) ?? [];
      const optionsGroupCustomer = responses[0]?.data.data?.map(item => ({
        ...item,
        value: item._token,
        label: item.group_name,
      }));
      const optionsGL = responses[1]?.data?.map(item => ({
        ...item,
        value: item.code,
        label: item.code,
      }));
      const optionsBank = responses[2]?.data.data?.map(item => ({
        ...item,
        value: item.bank_code,
        label: item.bank_name,
      }));

      // dispatch({ type: SET_OPTIONS_CUSTOMER, payload: optionsCustomer });
      dispatch({ type: SET_OPTIONS_GROUP_CUSTOMER, payload: optionsGroupCustomer });
      dispatch({ type: SET_OPTIONS_GL, payload: optionsGL });
      dispatch({ type: SET_OPTIONS_BANK, payload: optionsBank });
    } catch (error) {
      console.log('pelunasanPiutangReducer: ', error.stack);
    }
  };
};

export default pelunasanPiutangReducer;
