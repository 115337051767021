import { listMerk, listWarehouseLocation, listRoleName } from '../../utilities/dummy-data';

const {
  REACT_APP_USER_ROLE_OPTIONS,
  REACT_APP_USER_DETAIL,
  REACT_APP_USER_ROLE_DETAIL,
  REACT_APP_USER_ROLE_SUBSIDIARY,
} = process.env;

const initialState = {
  optionsStatus: ['Semua', 'Tidak Aktif', 'Aktif'],
  optionsKota: listWarehouseLocation,
  optionsRole: [],
  optionsMerk: listMerk,

  dateFrom: '',
  dateTo: '',

  selectedStatus: '',
  selectedKota: '',
  selectedRole: '',
  selectedMerk: '',
  searchKeyword: '',

  userDetail: {},
  userRoleDetail: {},
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_USER_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'DATA_CREATE_USER':
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_USER_ROLE_OPTIONS}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_USER_DETAIL}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_USER_ROLE_DETAIL}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_USER_ROLE_SUBSIDIARY}`:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default userManagementReducer;
