const { REACT_APP_SUBSIDIARY_DETAIL, REACT_APP_SUBSIDIARY_OPTIONS } = process.env;

const initialState = {
  optionsKota: [],
  optionsJenisSubsidiary: [
    { label: 'Perusahaan', value: 1 },
    { label: 'Bengkel', value: 2 },
  ],
  optionsSubsidiary: [],

  searchKeyword: '',
  selectedKota: '',
  selectedJenisSubsidiary: '',

  subsidiaryDetail: {},
};

const manageSubsidiaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_SUBSIDIARY_WITH_FILTER':
      return {
        ...state,
        ...action,
      };
    case 'CREATE_SUBSIDIARY_DATA':
      return {
        ...state,
        ...action,
      };
    case `initiate-${REACT_APP_SUBSIDIARY_DETAIL}`:
      return {
        ...state,
        ...action,
      };
    case `inisiate-${REACT_APP_SUBSIDIARY_OPTIONS}`:
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default manageSubsidiaryReducer;
