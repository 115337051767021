import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import useFetch from '../useFetch';
import { TOASTER_SHOW } from 'redux/actions/actionTypes';
/*
  ````````````
  useQueryData

  ````````````
*/

const useQueryData = (endpointName = '', params = {}, options = {}) => {
  //@params: Object || Function

  const dispatch = useDispatch();

  const { status, data, isLoading, isError, error, isSuccess, refetch, isFetching } = useQuery({
    queryKey: [endpointName, params],
    queryFn: useFetch(endpointName),
    refetchOnWindowFocus: false,
    cacheTime: 0,
    retry: 0,
    ...options,
  });

  useEffect(() => {
    if (isSuccess) {
      if (data !== null && data !== undefined) {
        if (data.msg !== undefined) {
          if (data.msg !== '') {
            dispatch({ type: TOASTER_SHOW, message: data.msg, isError: true });
          }
        }
      }
    }
  }, [isError, isLoading, status, error, isSuccess, dispatch, data]);

  return {
    status,
    data,
    isLoading: isLoading || isFetching,
    isError,
    error,
    refetch,
  };
};

export default useQueryData;
