import Axios from '../../utilities/axios-config';

const PREFIX = 'PENJUALAN/RETUR_PENJUALAN';
const SET_OPTIONS_CUSTOMER = `${PREFIX}/SET_OPTIONS_CUSTOMER`;
const SET_OPTIONS_SALESMAN = `${PREFIX}/SET_OPTIONS_SALESMAN`;
const SET_OPTIONS_PENJUALAN = `${PREFIX}/SET_OPTIONS_PENJUALAN`;
const SET_OPTIONS_LOKASI_GUDANG = `${PREFIX}/SET_OPTIONS_LOKASI_GUDANG`;
const SET_OPTIONS_BARANG = `${PREFIX}/SET_OPTIONS_BARANG`;
const SET_LOADING_OPTIONS = `${PREFIX}/SET_LOADING_OPTIONS`;

const {
  REACT_APP_CUSTOMER_OPTIONS,
  REACT_APP_SALESMAN_OPTIONS,
  REACT_APP_RETUR_PENJUALAN_FAKTUR_LIST,
  REACT_APP_LOCATION_GUDANG_OPTIONS,
  REACT_APP_DAFTAR_BARANG_PRODUCT_OPTIONS,
} = process.env;

const initialState = {
  loadingOptions: false,
  optionsCustomer: [{ value: undefined, label: 'Load data...' }],
  optionsSalesman: [
    {
      value: undefined,
      label: 'Load data...',
    },
  ],
  optionsPenjualan: [{ value: null, label: null }],
  optionsGudang: [
    {
      value: undefined,
      label: 'Load data...',
    },
  ],
  optionsBarang: [
    {
      value: undefined,
      label: 'Load data...',
    },
  ],
};

const subPenjualanReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_OPTIONS:
      return {
        ...state,
        loadingOptions: action.payload,
      };
    case SET_OPTIONS_CUSTOMER:
      return {
        ...state,
        optionsCustomer: action.payload,
      };
    case SET_OPTIONS_BARANG:
      return {
        ...state,
        optionsBarang: action.payload,
      };
    case SET_OPTIONS_SALESMAN:
      return {
        ...state,
        optionsSalesman: action.payload,
      };
    case SET_OPTIONS_PENJUALAN:
      return {
        ...state,
        optionsPenjualan: action.payload,
      };
    case SET_OPTIONS_LOKASI_GUDANG:
      return {
        ...state,
        optionsGudang: action.payload,
      };
    default:
      return state;
  }
};

export const loadOptions = () => {
  return async dispatch => {
    try {
      dispatch({ type: SET_LOADING_OPTIONS, payload: true });
      const responses = await Promise.all([
        // Axios.get(REACT_APP_CUSTOMER_OPTIONS),
        Axios.get(REACT_APP_SALESMAN_OPTIONS),
        Axios.post(REACT_APP_RETUR_PENJUALAN_FAKTUR_LIST, { rows_limit: 10000, page: 1 }),
        Axios.get(REACT_APP_LOCATION_GUDANG_OPTIONS),
        // Axios.get(REACT_APP_DAFTAR_BARANG_PRODUCT_OPTIONS),
      ]);

      // const optionsCustomer =
      //   responses[0]?.data.data?.map(item => ({
      //     ...item,
      //     value: item.customer_code,
      //     label: `${item.customer_code} - ${item.company_name}`,
      //   })) ?? [];
      const optionsSalesman =
        responses[0]?.data.data?.map(item => ({
          ...item,
          value: item.salesman_code,
          label: item.salesman_name,
        })) ?? [];
      const optionsPenjualan = responses[1]?.data.data?.map(item => ({
        ...item,
        value: item.invoice_number,
        label: item.invoice_number,
      }));
      const optionsGudang = responses[2]?.data.data?.map(item => ({
        ...item,
        value: item.warehouse_code,
        label: item.warehouse_name,
      }));

      // const optionsBarang = responses[3]?.data.data?.map(item => ({
      //   ...item,
      //   value: item.product_code,
      //   label: `${item.product_code} - ${item.product_name}`,
      // }));

      // dispatch({ type: SET_OPTIONS_CUSTOMER, payload: optionsCustomer });
      dispatch({ type: SET_OPTIONS_SALESMAN, payload: optionsSalesman });
      dispatch({ type: SET_OPTIONS_PENJUALAN, payload: optionsPenjualan });
      dispatch({ type: SET_OPTIONS_LOKASI_GUDANG, payload: optionsGudang });
      // dispatch({ type: SET_OPTIONS_BARANG, payload: optionsBarang });
      dispatch({ type: SET_LOADING_OPTIONS, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADING_OPTIONS, payload: false });
      console.log('poPenjualanReducer: ', error.stack);
    }
  };
};

export default subPenjualanReducer;
