import React from 'react';
import PropTypes from 'prop-types';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import {
  Row,
  Column,
  Divider,
  Paper,
  BorderBox,
  Box,
  Subtitle2,
  Heading5,
  RowFixed300,
  P,
} from 'components/print-viewer-pdf';
import { useSelector } from 'react-redux';

const PrintPdfInvoicePenjualanBanForm = props => {
  const modulPenjualan = useSelector(state => state?.modulPenjualan);
  let { dataPrint } = modulPenjualan;

  let { printMode } = props;
  let {
    listDataInvoicePenjualanBan,
    tableHeaderInvoicePenjualanBan,
    valueNpwp,

    //*Invoice Jenis Penjualan Ban;
    valueAreaJual,
    valueSalesman,
    valueTop,
    valueKet,
    valueNamaPenjual,
    valueAlamatPenjual,
    valueNoFaktur,
    valueTanggalFaktur,
    valueCustomer,
    valueAlamatCustomer,
    valueNpwpCustomer,
    valueDiscountC,
    valueDiscountP,
    valueRekeningBank,
    valueKeteranganPenbayaran,
    valueTotalAll,
  } = dataPrint;

  console.log('dataPrint PENJUALAN BAN : ', dataPrint);

  return (
    <Paper size="A3" background="white" ruler={false} printMode={printMode}>
      <BorderBox>
        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}>
            <Heading5>{valueNamaPenjual}</Heading5>
            <Divider mt={2} mb={2} />
            <Box width={300}>
              <Subtitle2>{valueAlamatPenjual}</Subtitle2>
            </Box>

            <Divider mt={1} mb={1} />

            <Subtitle2 bold>NPWP : </Subtitle2>
            <Subtitle2>{valueNpwp}</Subtitle2>

            <Divider mt={2} mb={2} />
            <Divider mt={1} mb={1} />
            <Subtitle2 bold>Area Jual : </Subtitle2>
            <Subtitle2>{valueAreaJual}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>Salesman : </Subtitle2>
            <Subtitle2>{valueSalesman}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold> T.O.P : </Subtitle2>
            <Subtitle2>{valueTop}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>KET :</Subtitle2>
            <Subtitle2>{valueKet}</Subtitle2>
          </Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}>
            <Subtitle2 bold>NO. FAKTUR :</Subtitle2>
            <Subtitle2>{valueNoFaktur}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>TANGGAL FAKTUR:</Subtitle2>
            <Subtitle2>{valueTanggalFaktur}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>NAMA CUSTOMER :</Subtitle2>
            <RowFixed300>
              <Subtitle2>{valueCustomer}</Subtitle2>
            </RowFixed300>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>ALAMAT :</Subtitle2>
            <RowFixed300>
              <Subtitle2>{valueAlamatCustomer}</Subtitle2>
            </RowFixed300>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>NPWP :</Subtitle2>
            <Subtitle2>{valueNpwpCustomer}</Subtitle2>
          </Column>
        </Row>

        <Box mHorizontal={40} mVertical={4}>
          <Table data={listDataInvoicePenjualanBan}>
            <TableHeader textAlign="center">
              {tableHeaderInvoicePenjualanBan.length > 0
                ? tableHeaderInvoicePenjualanBan.map((item, i) => (
                    <TableCell key={i} weighting={['NO'].includes(item) ? 0.2 : undefined}>
                      <Subtitle2 bold>{item}</Subtitle2>
                    </TableCell>
                  ))
                : null}
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={{ fontFamily: 'Courier', textAlign: 'center' }}
                weighting={0.2}
                getContent={r => r.no}
              />
              <DataTableCell style={{ fontFamily: 'Courier', textAlign: 'center' }} getContent={r => r.nama_barang} />
              <DataTableCell
                style={{ fontFamily: 'Courier', textAlign: 'center' }}
                getContent={r => r.jumlah_barang + ' ' + r?.productSatuan}
                // getContent={r => r.jumlah_barang}
              />
              <DataTableCell style={{ fontFamily: 'Courier', textAlign: 'center' }} getContent={r => r.harga_satuan} />
              {/* <DataTableCell style={{ fontFamily: 'Courier', textAlign: 'center' }} getContent={r => r.keterangan} /> */}
              <DataTableCell style={{ fontFamily: 'Courier', textAlign: 'center' }} getContent={r => r.disc} />
              <DataTableCell style={{ fontFamily: 'Courier', textAlign: 'center' }} getContent={r => r.netto} />
              <DataTableCell style={{ fontFamily: 'Courier', textAlign: 'center' }} getContent={r => r.jumlah} />
            </TableBody>
          </Table>
        </Box>

        <Row>
          <Column mHorizontal={48} mVertical={32}>
            <Box width={300}>
              <Subtitle2 bold>REKENING : {valueRekeningBank}</Subtitle2>
            </Box>
            <Divider mt={1} mb={1} />

            <Box width={300}>
              <Subtitle2 bold>KETERANGAN : {valueKeteranganPenbayaran}</Subtitle2>
            </Box>
          </Column>

          <Column mHorizontal={72} mVertical={32}>
            <Subtitle2 bold>DISCOUNT C </Subtitle2>
            <Divider mt={1} mb={1} />
            <Subtitle2 bold>DISCOUNT P</Subtitle2>
            <Divider mt={1} mb={1} />
            <Subtitle2 bold>JUMLAH</Subtitle2>
          </Column>
          <Column mHorizontal={1} mVertical={32}>
            <Subtitle2> : {valueDiscountC}</Subtitle2>
            <Divider mt={1} mb={1} />
            <Subtitle2> : {valueDiscountP}</Subtitle2>
            <Divider mt={1} mb={1} />
            <Subtitle2> : {valueTotalAll}</Subtitle2>
          </Column>
        </Row>

        <Row justifyContent="center">
          <Column mHorizontal={48} mVertical={32}>
            <Subtitle2 bold>Penerima</Subtitle2>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <Subtitle2 bold>Hormat Kami</Subtitle2>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
        </Row>
      </BorderBox>
    </Paper>
  );
};
PrintPdfInvoicePenjualanBanForm.defaultProps = {
  printMode: false,
};
PrintPdfInvoicePenjualanBanForm.propTypes = {
  dataPrint: PropTypes.object.isRequired,
  printMode: PropTypes.bool,
};
export default PrintPdfInvoicePenjualanBanForm;
