import { SEARCH_PO_PEMBELIAN_WITH_FILTER, FORCE_CETAK_PDF } from '../actions/actionTypes';

import {
  listBarang,
  listNoFaktur,
  listPoPembelian,
  listDataPrintPoPembelian,
  listKodeSupplier,
} from '../../utilities/dummy-data';

const {
  REACT_APP_LOCATION_GUDANG_OPTIONS,
  REACT_APP_LIST_NO_PO,
  REACT_APP_LIST_NO_PENERIMAAN,
  REACT_APP_LIST_NO_INVOICE,
  REACT_APP_SUPPLIER_OPTIONS,
  REACT_APP_LIST_SUPPLIER_INVOICE,
} = process.env;

const dataDummyNoPenerimaanBarang = [
  {
    value: 'CE-10000-MILEXL-HD',
    label: '0001221323123123',
    satuan: 'PCS',
    jumlah_barang: '1',
    harga_dpp: 'Rp 2.000.000',
    diskon: '10%',
  },
  {
    value: 'CE-10000-MILEXL-HD',
    label: '4234254542524524',
    satuan: 'PCS',
    jumlah_barang: '1',
    harga_dpp: 'Rp 1.000.000',
    diskon: '20%',
  },
];
const TOP = 'TOP';
const CASH = 'Cash';

const PO = 'PO';
const TANPA_PO = 'Tanpa PO';

const WITH_INVOICE = 'Dengan Invoice';
const WITHOUT_INVOICE = 'Tanpa Invoice';

const initialState = {
  optionsSupplierInvoice: [{ value: '-', label: '' }],
  optionsNameSupplier: [{ value: '-', label: '' }],
  optionsSupplier: ['Supplier 1', 'Supplier 2', 'Supplier 3'], //*Deprecated
  optionsSuppliers: listKodeSupplier, //*Deprecated
  optionsNoPenerimaanBarang: dataDummyNoPenerimaanBarang,
  optionsNoInvoice: [{ value: '', label: '' }],
  optionsLocationGudang: [
    {
      value: 'area_1',
      label: 'Area 1',
    },
    {
      value: 'area_2',
      label: 'Area 2',
    },
    {
      value: 'area_3',
      label: 'Area 3',
    },
    {
      value: 'area_4',
      label: 'Area 4',
    },
  ],
  optionsExport: ['PDF', 'CSV'],
  optionsBarang: listBarang,
  optionsTipePembayaran: [TOP, CASH],
  optionsJenisTransaksi: ['Pembelian rinci', 'Pembelian Ringkas', 'Retur Pembelian'],
  optionsNoFaktur: listNoFaktur,
  optionsJenisTransaksiLaporanHutang: [
    'Mutasi Hutang',
    'Laporan Hutang',
    'Hutang Jatuh Tempo',
    'Pelunasan Hutang',
    'Laporan Sisa Hutang',
  ],
  optionsTipeLaporanHutang: ['Global', 'Rinci'],
  optionsDiskon: ['Rupiah', '%'],
  optionsPoPembelian: [{ value: '...', label: 'Load data...' }],
  dateFrom: '',
  dateTo: '',
  datePembelian: '',
  datePenerimaan: '',
  dateTanggalJatuhTempo: '',
  dateInvoice: '',
  label: 'Area 1',
  dateRetur: '',

  selectedSupplier: '',
  selectedLocationGudang: '',
  selectedExportTo: 'PDF',
  selectedBarang: '',
  selectedTipePembayaran: '',
  selectedJenisTransaksi: '',
  selectedNoFaktur: '',
  selectedKelompokBarang: '',
  selectedTipeLaporanHutang: '',
  selectedDiscount: '',
  selectedPoPembelian: '',

  noPo: '',
  valueJumlahBarang: 0,
  keterangan: '',

  noPenerimaan: '',

  noInvoice: '',
  noPenerimaanBarang: '',
  noSo: '',
  noRetur: '',
  noPelunasan: '',
  noNota: '',

  rekeningGl: '',

  listKeteranganDebetNote: [],
  listKeteranganKreditNote: [],
  listJumlah: [],

  dataPrint: {
    headerTitle: 'Cetak PO Pembelian',
    listData: listDataPrintPoPembelian,
    tableHeader: ['No', 'Nama Barang', 'QTY', 'Harga Satuan', 'Diskon(%)', 'Diskon(Rp)', 'Subtotal'],

    valueKepada: '-',
    valuePic: '-',
    valueNoTelpPic: '-',

    valueNoPO: '',
    valueTglPo: '-',
    valueNamaDealer: '-',
    valueAlamatDealer: '-',
    valueNpwp: '-',

    valueDiKirimKe: '-',
    valueKirimKeAlamat: '-',
    valurPenerimaKirimanAtasNama: '-',
    valueNomorTelponPenerimaKiriman: '-',

    valuePembayaranKepada: '-',
    valueAlamatPenerimaPembayaran: '-',
    valuePenerimaPembayaranAtasNama: '-',
    valueNomorTeleponPenerimaPembayaran: '-',
  },

  filteredWith: {
    page: 1,
    rows_limit: 20,
    filter_sort: 'desc',
    filter_sort_by: 'created_at',
  },
  TOP,
  CASH,
  PO,
  TANPA_PO,
  WITH_INVOICE,
  WITHOUT_INVOICE,
};

const pembelianReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_PO_PEMBELIAN_WITH_FILTER:
      return {
        ...state,
        ...action,
      };

    case 'CREATE_PO_PEMBELIAN_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_DEBET_NOTE_HUTANG_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_KREDIT_NOTE_HUTANG_DATA':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_DAFTAR_PEMBELIAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_PENERIMAAN_BARANG_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_OUTSTANDING_PEMBELIAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_INVOICE_PEMBELIAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_RETUR_PEMBELIAN_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_PELUNASAN_HUTANG_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_DEBET_NOTE_HUTANG_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'SEARCH_KREDIT_NOTE_HUTANG_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_LIST_SUPPLIER_INVOICE}`:
      return {
        ...state,
        optionsSupplierInvoice: action?.optionsSupplierInvoice,
      };

    case `inisiate-${REACT_APP_SUPPLIER_OPTIONS}`:
      return {
        ...state,
        optionsNameSupplier: action?.optionsNameSupplier,
      };

    case `inisiate-${REACT_APP_LOCATION_GUDANG_OPTIONS}`:
      return {
        ...state,
        optionsLocationGudang: action?.optionsLocationGudang,
      };

    case `inisiate-${REACT_APP_LIST_NO_PO}`:
      return {
        ...state,
        optionsPoPembelian: action?.optionsPoPembelian,
      };

    case FORCE_CETAK_PDF:
      return {
        ...state,
        dataPrint: action?.dataPrint,
      };

    case `inisiate-${REACT_APP_LIST_NO_PENERIMAAN}`:
      return {
        ...state,
        optionsNoPenerimaanBarang: action?.optionsNoPenerimaanBarang,
      };

    case `inisiate-${REACT_APP_LIST_NO_INVOICE}`:
      return {
        ...state,
        optionsNoInvoice: action?.optionsNoInvoice,
      };

    default:
      return state;
  }
};

export default pembelianReducer;
