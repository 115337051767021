import React, { useEffect } from 'react';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import {
  Row,
  Column,
  Divider,
  Paper,
  BorderBox,
  Box,
  Subtitle2,
  Heading5,
  Heading6,
} from 'components/print-viewer-pdf';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { useQueryData } from 'utilities/hook';
import PropTypes from 'prop-types';

const PrintPdfSuratJalanTransferStokForm = props => {
  let { dataPrint, printMode } = props;

  let {
    // headerTitle,
    listData,
    tableHeader,
    valueNamaSurat,
    valueLokasiAwal,

    valueTanggal,
    valueLokasiTujuan,
    valueNomorFaktur,
    valueNoPlatPolisi,
    valueDriveName,

    valueSuratKeluarBy,
    valueSuratKeluarCheckedBy,

    _token,
  } = dataPrint;

  let { REACT_APP_TRANSFER_STOCK_DETAIL } = process.env;
  const { data, isLoading, refetch } = useQueryData(
    REACT_APP_TRANSFER_STOCK_DETAIL,
    {
      _token: _token,
    },
    {
      enabled: false,
    },
  );

  const valueAlamat = data?.data?.subsidiary_address || '-';
  const headerTitle = data?.data?.subsidiary_name || '';
  const valueNpwp = data?.data?.subsidiary_npwp || '-';

  useEffect(() => {
    if (_token) refetch();
  }, [refetch, _token]);
  const details = (data?.data?.detail_barang || []).map((item, index) => {
    return {
      no: index + 1,
      nama_barang: item?.nama_barang || '',
      merk_barang: item?.merk_barang || '',
      ukuran: item?.satuan?.ukuran || '',
      qty: item?.jumlah_barang || '',
      jenis_barang: item?.jenis_barang || '',
      keterangan: item?.keterangan || '',
    };
  });

  if (isLoading) return <LoadingSkeleton count={3} />;
  return (
    <Paper size="A3" background="white" ruler={false} printMode={printMode}>
      <BorderBox>
        {/* <Header align='left' bold={true}>
              {headerTitle}
            </Header> */}

        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}>
            <Heading5>{headerTitle}</Heading5>
            <Divider mt={2} mb={2} />
            <Box width={300}>
              <Subtitle2>{valueAlamat}</Subtitle2>
            </Box>

            <Divider mt={1} mb={1} />

            <Subtitle2 bold>NPWP : </Subtitle2>
            <Subtitle2>{valueNpwp}</Subtitle2>

            <Divider mt={2} mb={2} />
            <Heading6>{valueNamaSurat} </Heading6>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>LOKASI AWAL : </Subtitle2>
            <Subtitle2>{valueLokasiAwal}</Subtitle2>
          </Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}>
            <Subtitle2 bold>TANGGAL :</Subtitle2>
            <Subtitle2>{valueTanggal}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>NOMOR TRANSFER :</Subtitle2>
            <Subtitle2>{valueNomorFaktur}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>TUJUAN :</Subtitle2>
            <Subtitle2>{valueLokasiTujuan}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>NO. PLAT POLISI :</Subtitle2>
            <Subtitle2>{valueNoPlatPolisi}</Subtitle2>

            <Divider mt={1} mb={1} />
            <Subtitle2 bold>SUPIR :</Subtitle2>
            <Subtitle2>{valueDriveName}</Subtitle2>
          </Column>
        </Row>

        <Box mHorizontal={40} mVertical={4}>
          <Table data={details}>
            <TableHeader textAlign="center">
              {tableHeader.length > 0
                ? tableHeader.map((item, i) => (
                    <TableCell key={i}>
                      <Subtitle2 bold>{item}</Subtitle2>
                    </TableCell>
                  ))
                : null}
            </TableHeader>
            <TableBody>
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.no} />
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.nama_barang} />
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.merk_barang} />
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.ukuran} />
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.qty} />
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.jenis_barang} />
              <DataTableCell style={{ textAlign: 'center', fontFamily: 'Courier' }} getContent={r => r.keterangan} />
            </TableBody>
          </Table>
        </Box>

        <Row>
          <Column mHorizontal={48} mVertical={32}>
            <Subtitle2 bold>DI KELUARKAN OLEH </Subtitle2>
            <Divider mt={1} mb={1} />
            <Subtitle2 bold>DI HITUNG/ DI PERIKSA OLEH</Subtitle2>
          </Column>
          {/* <Column mHorizontal={4} mVertical={32}>
            <Subtitle2> : {valueSuratKeluarBy}</Subtitle2>
            <Divider mt={1} mb={1} />
            <Subtitle2> : {valueSuratKeluarCheckedBy}</Subtitle2>
          </Column> */}
        </Row>

        <Row justifyContent="center">
          <Column mHorizontal={48} mVertical={32}>
            <Subtitle2 bold>KEPALA GUDANG</Subtitle2>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <Subtitle2 bold>SUPIR</Subtitle2>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <Subtitle2 bold>DITERIMA OLEH</Subtitle2>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
        </Row>
      </BorderBox>
    </Paper>
  );
};

PrintPdfSuratJalanTransferStokForm.defaultProps = {
  printMode: false,
};
PrintPdfSuratJalanTransferStokForm.propTypes = {
  dataPrint: PropTypes.object.isRequired,
  printMode: PropTypes.bool,
};
export default PrintPdfSuratJalanTransferStokForm;
