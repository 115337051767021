// import {  SEARCH_PO_PEMBELIAN_WITH_FILTER } from '../actions/actionTypes'

import {
  listBank,
  listNoFaktur,
  listCustomer,
  listCekGiro,
  listWarehouseLocation,
  listRekGL,
} from '../../utilities/dummy-data';

let {
  REACT_APP_INISIATE_LIST_KODE_KAS_BANK,
  REACT_APP_AKUN_REK_GL_OPTIONS,
  REACT_APP_GET_NO_CEK_GIRO,
  REACT_APP_GENERATE_CODE_PEMBATALAN_GIRO,
  REACT_APP_GENERATE_TRANSFER_TUNAI_KODE,
  REACT_APP_RIWAYAT_KAS_BANK_DETAIL,
} = process.env;

const initialState = {
  optionsJenis: ['Semua', 'Kas', 'Bank'],
  optionKodeKasBank: listBank,
  optionsGL: listRekGL,
  optionsCekGiro: listCekGiro,
  optionsGLWithoutBank: [],
  optionsTransaksi: [
    { value: 'Semua', label: 'Kas/Bank (Semua akun GL)' },
    { value: 'Kas/Bank', label: 'Kas/Bank' },
    { value: 'Giro', label: 'Giro' },
    { value: 'Giro Piutang', label: 'Giro Piutang' },
  ],
  optionsJenisTransaksiKasBank: ['Semua', 'Penerimaan', 'Pengeluaran'],
  optionsJenisTransaksiGiro: ['Giro masuk', 'Giro keluar'],

  dateFrom: '',
  dateTo: '',
  dateJatuhTempo: '',
  datePembatalan: '',

  selectedJenisPenerimaan: '',
  selectedKodeKasBank: '',
  selectedCekGiro: '',
  selectedTransaksi: '',
  selectedJenisTransaksiKasBank: '',
  selectedJenisTransaksiGiro: '',

  valueNamaKasBank: '',
  valueNomor: '',
  valueNoPembatalan: '',
  valueKeterangan: '',
  filteredWith: {
    //#
    page: 1,
    rows_limit: 20,
    // filter_sort: 'desc',
    // filter_sort_by: 'created_at',
  },
  detailGeneratePembatalanGiroCode: {},
  detailNomorTransferTunai: {},
  listRiwayatKasBankTahunan: [],
  listRiwayatKasBankBulanan: [],
  listRiwayatKasBankDetailPerTanggal: [],
};

const kasBankReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SEARCH_KAS_BANK_WITH_FILTER':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_PENERIMAAN_KAS_BANK_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_TRANSFER_TUNAI_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_GIRO_MASUK_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_GIRO_KELUAR_DATA':
      return {
        ...state,
        ...action,
      };

    case 'CREATE_PEMBATALAN_GIRO_DATA':
      return {
        ...state,
        ...action,
      };

    case `inisiate-${REACT_APP_INISIATE_LIST_KODE_KAS_BANK}`:
      return {
        ...state,
        optionKodeKasBank: action.optionKodeKasBank,
      };

    case `inisiate-${REACT_APP_AKUN_REK_GL_OPTIONS}`:
      return {
        ...state,
        optionsGL: action.optionsGL,
      };

    case `inisiate-WITHOUT-BANK_${REACT_APP_AKUN_REK_GL_OPTIONS}`:
      return {
        ...state,
        optionsGLWithoutBank: action.optionsGLWithoutBank,
      };

    case `inisiate-${REACT_APP_GET_NO_CEK_GIRO}`:
      return {
        ...state,
        optionsCekGiro: action.optionsCekGiro,
      };

    case `inisiate-${REACT_APP_GENERATE_CODE_PEMBATALAN_GIRO}`:
      return {
        ...state,
        detailGeneratePembatalanGiroCode: action.detailGeneratePembatalanGiroCode,
      };

    case `inisiate-${REACT_APP_GENERATE_TRANSFER_TUNAI_KODE}`:
      return {
        ...state,
        detailNomorTransferTunai: action.detailNomorTransferTunai,
      };

    case `detail-riwayat-tahunan-${REACT_APP_RIWAYAT_KAS_BANK_DETAIL}`:
      return {
        ...state,
        listRiwayatKasBankTahunan: action.listRiwayatKasBankTahunan,
      };

    default:
      return state;
  }
};

export default kasBankReducer;
