import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { DataTableCell, Table, TableBody, TableCell, TableHeader } from '@david.kucsai/react-pdf-table';
import { Row, Column, P, Divider, Header, Paper, BorderBox, Box } from 'components/print-viewer-pdf';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { useQueryData } from 'utilities/hook';

const styles = StyleSheet.create({
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
});

const PrintPdfPoPembelianForm = props => {
  let { dataPrint, printMode } = props;
  let {
    headerTitle,
    tableHeader,
    valueNomorTelponPenerimaKiriman,
    valueNoPO,
    valueTglPo,
    // valuePembayaranKepada,
  } = dataPrint;
  let { REACT_APP_PO_PEMBELIAN_DETAIL } = process.env;
  const { data, isLoading, refetch } = useQueryData(
    REACT_APP_PO_PEMBELIAN_DETAIL,
    {
      purchase_number: valueNoPO,
    },
    {
      enabled: false,
    },
  );

  const valuePpn11Percent = data?.data?.ppn?.string || 0;
  const valueTotal = data?.data?.total?.string || 0;

  useEffect(() => {
    if (valueNoPO) refetch();
  }, [refetch, valueNoPO]);
  const details = (data?.data?.details || []).map((item, index) => {
    return {
      no: index + 1,
      nama_barang: item?.product?.name || '',
      qty: item?.quantity || '',
      harga_satuan: item?.price?.string || '',
      // ppn: item?.ppn?.string || '',
      total: item?.subtotal?.string || '',
      discount_price: item?.discount_price?.string || '',
      discount_percent: item?.discount_percent || 0,
    };
  });

  const valueKepada = data?.data && data?.data?.supplier?.name;
  const valuePicName = data?.data && data?.data?.supplier?.pic_name;
  const valueNoTelpPic = data?.data && data?.data?.supplier?.phone_number;
  const valueAlamatSupplier = data?.data && data?.data?.supplier?.alamat;

  const valueAlamatPengirimanKepada = data?.data && data?.data?.company?.name;
  const valuePenagihanKepada = data?.data && data?.data?.company?.name;
  const valueNamaPicFakturPajak = data?.data && data?.data?.supplier?.nama_faktur_pajak;

  const valueAlamatGudang = data?.data && data?.data?.warehouse?.name;

  const valueNamaPenandatanganKonfigurasiPerusahaan = (data?.data && data?.data?.company?.penandatangan) || '-';
  const valueTeleponPenandatanganKonfigurasiPerusahaan = (data?.data && data?.data?.company?.no_telpon) || '-';
  const valueAlamatPenandatanganKonfigurasiPerusahaan = (data?.data && data?.data?.company?.alamat) || '-';
  const valueNamaDealerPenandatanganKonfigPerusahaan = (data?.data && data?.data?.company?.name) || '-'; //sesuai konfigurasi perusahaan
  const valueNpwpPenandatanganKonfigurasiPerusahaan = (data?.data && data?.data?.company?.npwp) || '-'; //sesuai konfigurasi perusahaan

  // console.log("DATA : ", data)

  if (isLoading) return <LoadingSkeleton count={3} />;
  return (
    <Paper size="A3" background="#E4E4E4" ruler={false} printMode={printMode}>
      <BorderBox>
        <Header align="center" bold>
          {valueNamaDealerPenandatanganKonfigPerusahaan}
        </Header>

        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}>
            <P bold>Kepada :</P>
            <Box width={300}>
              <P>{valueKepada}</P>
            </Box>

            <Divider mt={8} mb={8} />

            <P bold>PIC :</P>
            <P>{valuePicName}</P>

            <Divider mt={8} mb={8} />

            <P bold>No telepon : </P>
            <P>{valueNoTelpPic}</P>

            <Divider mt={8} mb={8} />

            <P bold> Alamat :</P>
            <P>{valueAlamatSupplier} </P>

            {/* <Divider mt={24} mb={8} /> */}
          </Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}>
            <P bold>No PO :</P>
            <P>{valueNoPO}</P>

            <Divider mt={8} mb={8} />

            <P bold>Tanggal PO :</P>
            <Divider mt={1} mb={1} />
            <P>{valueTglPo}</P>

            <Divider mt={8} mb={8} />

            <P bold> Nama Dealer :</P>
            <P>{valueNamaDealerPenandatanganKonfigPerusahaan}</P>

            <Divider mt={8} mb={8} />

            <P bold>NPWP :</P>
            <P>{valueNpwpPenandatanganKonfigurasiPerusahaan}</P>

            {/* <Divider mt={24} mb={8} /> */}
          </Column>
        </Row>

        {/* <Divider mt={48} /> */}
        <BorderBox width={'100%'} padding={0} margin={0} borderWidth={1} />

        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}>
            <P bold>Pengiriman ke :</P>
            <P>{valueAlamatPengirimanKepada}</P>
            <Divider mt={8} mb={8} />
            <P bold>Alamat Gudang:</P>
            <Box width={300}>
              <P>{valueAlamatGudang} </P>
            </Box>
            <Divider mt={8} mb={8} />
            <P bold>PIC :</P>
            <P>{valueNamaPicFakturPajak}</P>
            <Divider mt={8} mb={8} />
            <P bold>No Telepon :</P>
            <P>{valueNomorTelponPenerimaKiriman}</P>
          </Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}>
            <P bold>Penagihan kepada :</P>
            <P>{valuePenagihanKepada}</P>
            <Divider mt={8} mb={8} />
            <P bold>Alamat :</P>
            <Box width={300}>
              <P>{valueAlamatPenandatanganKonfigurasiPerusahaan}</P>
            </Box>
            <Divider mt={8} mb={8} />
            <P bold>Atas Nama :</P>
            <P>{valueNamaPenandatanganKonfigurasiPerusahaan}</P>

            <Divider mt={8} mb={8} />
            <P bold>No. Telepon :</P>
            <P>{valueTeleponPenandatanganKonfigurasiPerusahaan}</P>
          </Column>
        </Row>

        <Box mHorizontal={40} mVertical={4}>
          <Table data={details}>
            <TableHeader textAlign="center">
              {tableHeader.length > 0
                ? tableHeader.map((item, i) => {
                    if (item == 'No') {
                      return (
                        <TableCell key={i} weighting={0.2}>
                          <P bold>{item}</P>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell key={i}>
                          <P bold>{item}</P>
                        </TableCell>
                      );
                    }
                  })
                : null}
            </TableHeader>
            <TableBody>
              <DataTableCell weighting={0.2} style={{ textAlign: 'center' }} getContent={r => r.no} />
              <DataTableCell style={{ textAlign: 'center', fontSize: 10 }} getContent={r => r.nama_barang} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.qty} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.harga_satuan} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.discount_percent} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.discount_price} />
              <DataTableCell style={{ textAlign: 'center' }} getContent={r => r.total} />
            </TableBody>
          </Table>
          <Table
            data={[
              {
                ppn: 'PPN 11% :',
                dummy1: '',
                dummy2: '',
                dummy3: '',
                dummy4: '',
                dummy5: '',
                dummy6: '',
                total: valuePpn11Percent,
              },
              {
                ppn: 'TOTAL :',
                dummy1: '',
                dummy2: '',
                dummy3: '',
                dummy4: '',
                dummy5: '',
                dummy6: '',
                total: valueTotal,
              },
            ]}
          >
            <TableBody>
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.ppn} />
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.dummy1} />
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.dummy2} />
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.dummy3} />
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.dummy4} />
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.dummy5} />
              <DataTableCell style={{ borderRight: 0 }} getContent={r => r.dummy6} />
              <DataTableCell style={{ marginLeft: -11 }} getContent={r => r.total} />
            </TableBody>
          </Table>
        </Box>

        {/* TABLE PPN & ALL TOALL  */}

        <Row>
          {/* LEFT SIDE */}
          <Column mHorizontal={48} mVertical={32}></Column>

          {/* RIGHT SIDE */}
          <Column mHorizontal={24} mVertical={32}>
            <P bold>PPN 11%:</P>
            <P>{valuePpn11Percent}</P>

            <Divider mt={8} mb={8} />
            <P bold>Total :</P>
            <P>{valueTotal}</P>
          </Column>
        </Row>

        <Row>
          <Column mHorizontal={48} mVertical={32}>
            <P bold>Keterangan :</P>
            <BorderBox width={600} height={64} padding={0} margin={0}></BorderBox>
          </Column>
        </Row>

        <Row>
          <Column mHorizontal={48} mVertical={32}>
            <P>Hormat Kami,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Mengetahui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Mengetahui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
          <Column mHorizontal={48} mVertical={32}>
            <P>Disetujui,</P>
            <Divider mt={48} />
            <BorderBox width={72} padding={0} margin={0} borderWidth={1} />
          </Column>
        </Row>
      </BorderBox>
    </Paper>
  );
};

export default PrintPdfPoPembelianForm;
