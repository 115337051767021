import { Notifier } from '@airbrake/browser';

function initLogger() {
  if (!window.__clientLogger) {
    window.__clientLogger = new Notifier({
      projectId: 311376,
      environment: 'DEV',
      projectKey: '05e027a61bc8ada0ad262a7c9c3045d4',
    });
  }

  return window.__clientLogger;
}

export default initLogger;
