import {
  TOASTER_SHOW,
  TOASTER_CLOSE,
  SET,
  MODAL_SHOW,
  MODAL_CLOSE,
  MODAL_SHOW_TRASH,
  MODAL_CLOSE_TRASH,
  MODAL_SHOW_IMPORT,
  MODAL_CLOSE_IMPORT,
  SET_VALUE_SEARCH_DEBOUNCED,
  ENABLE_LOADER_OPTIONS_CUSTOMER,
  ENABLE_LOADER_OPTIONS_BARANG,
  SET_VALUE_FILTER_QUERY_PARAMS,
  RESET_FILTER_QUERY_PARAMS,
} from '../actions/actionTypes';

const initialState = {
  sidebarShow: 'responsive',
  isOpen: false,
  message: '',
  valueSearchAutoComplete: '',
  dataImport: {
    valueFiles: null,
    valueNameFile: '',
    valueSizeByte: '',
    valueSizeKiloByte: '',
  },
  dataDeleted: {},
  endpoint: '',
  endpointRefetch: '',
  isModalImport: false,
  isModalTrash: false,
  isModal: false,
  isError: false,
  isInfo: false,
  optionsStatusActivated: [
    { label: 'Aktif', value: 1 },
    { label: 'Tidak Aktif', value: 0 },
  ],
  valueSearchDebounced: '',
  isLoaderOptionsCustomer: false,
  isLoaderOptionsBarang: false,
  filterQueryParams: null, //{}
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        sidebarShow: action.sidebarShow,
      };

    case TOASTER_SHOW:
      return {
        ...state,
        isOpen: true,
        message: action.message,
        isError: action.isError,
        isInfo: action.isInfo,
      };

    case TOASTER_CLOSE:
      return {
        ...state,
        isOpen: false,
      };

    case 'DATA_IMPORT':
      return {
        ...state,
        ...action,
      };

    case MODAL_SHOW:
      return {
        ...state,
        isModal: true,
      };

    case MODAL_CLOSE:
      return {
        ...state,
        isModal: false,
      };

    case MODAL_SHOW_TRASH:
      return {
        ...state,
        isModalTrash: true,
        dataDeleted: action?.dataDeleted,
        endpoint: action?.endpoint,
        endpointRefetch: action?.endpointRefetch,
      };

    case MODAL_CLOSE_TRASH:
      return {
        ...state,
        isModalTrash: false,
      };

    case MODAL_SHOW_IMPORT:
      return {
        ...state,
        isModalImport: true,
        dataImport: action?.dataImport,
      };

    case MODAL_CLOSE_IMPORT:
      return {
        ...state,
        isModalImport: false,
      };

    case SET_VALUE_SEARCH_DEBOUNCED:
      return {
        ...state,
        valueSearchDebounced: action?.valueSearchDebounced,
      };

    case ENABLE_LOADER_OPTIONS_CUSTOMER:
      return {
        ...state,
        isLoaderOptionsCustomer: action?.isLoaderOptionsCustomer,
      };

    case ENABLE_LOADER_OPTIONS_BARANG:
      return {
        ...state,
        isLoaderOptionsBarang: action?.isLoaderOptionsBarang,
      };

    case SET_VALUE_FILTER_QUERY_PARAMS:
      return {
        ...state,
        filterQueryParams: action?.filterQueryParams,
      };

    case RESET_FILTER_QUERY_PARAMS:
      console.log('RESET_FILTER_QUERY_PARAMS...');
      return {
        ...state,
        filterQueryParams: null,
        valueSearchDebounced: '',
      };

    default:
      return state;
  }
};

export default uiReducer;
