import React, { useState } from 'react';

/*
  ```````````````
  useRadioButtons

  ```````````````
*/

//*Hook ini di gunakan untuk RadioGroup & RadioButton Component with Context.
const useRadioButtons = name => {
  const [value, setState] = useState(null);

  const handleChange = event => {
    setState(event.target.value);
  };

  const inputProps = {
    onChange: handleChange,
    name,
    type: 'radio',
  };

  return [value, inputProps];
};

export default useRadioButtons;
