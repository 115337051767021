import axios from 'axios';

const credentials = localStorage.getItem('token');

const axiosConfig = axios.create({
  // baseURL: 'http://banindo-api.l.logique.co.id', //*end point
  // baseURL: 'https://app.fakejson.com/q'
  baseURL: 'https://api.sumberbangroup.com',
});

axiosConfig.defaults.headers.common['Accept'] = 'application/json';
axiosConfig.defaults.headers.common['Content-Type'] = 'application/json';
axiosConfig.defaults.headers.common['x-api-key'] = 'API_BANINDO_KEY';

if (credentials == null || credentials == undefined || credentials == '') {
  axiosConfig.defaults.headers.common['Authorization'] = `Basic amt0OmpvZ2ph`;
}

if (credentials !== null && credentials !== undefined && credentials !== '') {
  // axiosConfig.defaults.headers.common['Banindo-token'] = `Bearer ${credentials}` ;
  axiosConfig.defaults.headers.common['Authorization'] = `Bearer ${credentials}`;
}

export default axiosConfig;
