import Axios from '../../axios-config';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TOASTER_SHOW } from 'redux/actions/actionTypes';

import axios from 'axios';
const CancelToken = axios.CancelToken;

/**
 * Hooks to generate fetch interface for react-query library
 * @param {string|Function} urlScheme scheme to generate url
 * @param {object} options Options for fetch API
 *                         { method: 'GET' or 'POST' or 'PUT' }
 *
 * @returns {Promise} Returns fetch interface to be consumed by react-query library
 *
 * @example
 * import { useQuery } from 'react-query';
 * import useFetch from '@hooks/useFetch';
 *
 * const { data, status, error } = useQuery(['userDetail'], useFetch('/user'));
 * -- or --
 * const { data, status, error } = useQuery(['userDetail', { userID: 1 }], useFetch(({ userID }) => `/user/${userID}`));
 */

const MUTATION_REQUESTS = ['POST', 'PUT'];

const useFetch = (urlScheme, options = {}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return param => {
    const usedVariables = MUTATION_REQUESTS.includes(options.method) ? param : param.queryKey[1];

    let finalUrl = String(urlScheme);

    let cancel;
    if (cancel !== undefined) {
      cancel();
    }

    const airBrakeLog = error => {
      console.log(`axios error at ${finalUrl}`, error.response);

      if (error.response !== undefined && error.response !== null) {
        if (window.__clientLogger && error.response.status !== 400) {
          let originalUrl = finalUrl;
          const credentials = localStorage.getItem('token');

          try {
            const { origin, pathname } = new URL(finalUrl);
            originalUrl = `${origin}${pathname}`;
          } catch {
            // do nothing
          }
          window.__clientLogger.notify({
            error: {
              message: `Error occurred for API: ${originalUrl}`,
              name: '[Banindo App]',
            },
            params: {
              error,
              apiUrl: finalUrl,
              apiToken: credentials,
              variables: usedVariables,
            },
          });
        }
      } else {
        dispatch({ type: TOASTER_SHOW, message: '"Whoops, something went wrong !', isError: true });
      }
    };

    /* Currently all network request is assume to be POST */
    const promise = Axios.post(finalUrl, usedVariables, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    })
      .then(res => {
        const { data } = res;
        return data;
      })
      .catch(error => {
        if (error) {
          airBrakeLog(error);

          if (error.response !== undefined && error.response !== null) {
            if (error.response.status === 401) {
              dispatch({ type: TOASTER_SHOW, message: `${error.response.data.msg}`, isError: true });
              history.push('/forbidden');
            }

            if (error.response.status === 403) {
              dispatch({ type: TOASTER_SHOW, message: `${error.response.data.msg}`, isError: true });
              history.push('/403');
            }

            if (error.response.status === 400) {
              dispatch({ type: TOASTER_SHOW, message: `${error.response.data.msg}`, isError: true });
            }

            if (error.response.status === 404) {
              dispatch({ type: TOASTER_SHOW, message: `${error.response.data.msg}`, isError: true });
            }

            if (error.response.status === 500) {
              dispatch({ type: TOASTER_SHOW, message: `${error.response.data.msg}`, isError: true });
            }
          } else {
            dispatch({ type: TOASTER_SHOW, message: '"Whoops, something went wrong !', isError: true });
          }
        }
      });

    return promise;
  };
};

export default useFetch;
